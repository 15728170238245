import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainCard: {
      width: '100%',
      minWidth: 'fit-content',
      maxWidth: 'calc(100% - 32px)',
      padding: '16px 0px 0px 0px',
      marginTop: '85px',
      maxHeight: 'calc(100vh - 112px)',
      overflowY: 'auto',
      border: 'none',
      margin: '0px 16px',
    },
    financialTableHeader: {
      display: 'flex',
      alignItems: 'center',
    },
    newTransactionButton: {
      '&.MuiIconButton-root': {
        backgroundColor: '#33B652',
        width: '40px',
        height: '40px',
        marginLeft: '16px',
      },
    },
    financialTable: {
      '&.MuiGrid-root': {
        position: 'relative',
        right: '15px',
      },
    },
    financialTotalValueContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginTop: '10px',
      paddingRight: '45px',
    },
    financialTotalValue: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginRight: '50px',
      borderRadius: '20px',
      width: '119px',
      height: '32px',
    },
    finacialPositiveValue: {
      backgroundColor: '#93C84C',
    },
    finacialNegativeValue: {
      backgroundColor: '#B3261E',
    },
    buttonGroup: {
      height: '50px',
      '&.MuiButtonGroup-root': {
        width: '300px',
        borderRadius: '20px',
      },
    },
    buttonGroupButton: {
      '&.MuiButton-root': {
        width: '150px',
        height: '50px',
        borderRadius: '20px',
        fontSize: '16px',
        fontWeight: 'ligther',
      },
    },
  })
)

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Dialog, Box, Tab, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { CompanyMinimalInfo } from '../../models/Company/Company'
import { ClientResponseI } from '../../models/Client/Client'
import { getClientByIdentifier } from '../../services/Client/ClientService'
import ClientPrimaryData from './ClientTabs/ClientPrimaryData'
import Loading from '../Loading/Loading'
import ClientContractData from './ClientTabs/ClientContractData'
import ClientContactData from './ClientTabs/ClientContactData'
import ClientObservations from './ClientTabs/ClientObservations'
import { PartnerResponse } from '../../models/Partner/Partner'
import { getPartnerByIdentifier } from '../../services/Partner/PartnerService'
import PartnerPrimaryData from './PartnerTabs/PartnerPrimaryData'
import PartnerTechnicalData from './PartnerTabs/PartnerTechnicalData'
import useStyles from './Styles/ViewInfoModalStyle'
import PartnerContacts from './PartnerTabs/PartnerContacts'

interface ViewInfoModalProps {
  open: boolean
  handleModalClose: () => void
  client?: CompanyMinimalInfo
  partner?: CompanyMinimalInfo
}

type tabValuesType = {
  label: string
  value: string
}

const ViewInfoModal: React.FC<ViewInfoModalProps> = ({ open, handleModalClose, client, partner }) => {
  const [tabValues, setTabValues] = React.useState<tabValuesType[]>([])
  const [selectedTab, setSelectedTab] = React.useState<string>('Dados Primários')
  const [loading, setLoading] = React.useState<boolean>(false)
  const [clientInfo, setClientInfo] = React.useState<ClientResponseI>({} as ClientResponseI)
  const [partnerInfo, setPartnerInfo] = React.useState<PartnerResponse>({} as PartnerResponse)
  const navigate = useNavigate()
  const classes = useStyles()
  const companyName = client?.name || partner?.name

  const clientTabValues: tabValuesType[] = [
    { label: 'Dados Primários', value: 'Dados Primários' },
    { label: 'Dados do contrato', value: 'Dados do contrato' },
    { label: 'Contatos', value: 'Contatos' },
    { label: 'Observações', value: 'Observações' },
  ]

  const partnerTabValues: tabValuesType[] = [
    { label: 'Dados Primários', value: 'Dados Primários' },
    { label: 'Dados Técnicos', value: 'Dados Técnicos' },
    { label: 'Contatos', value: 'Contatos' },
  ]

  const getClientInfoByIdentifier = async (identifier: string): Promise<ClientResponseI> => {
    return getClientByIdentifier(identifier)
  }

  const setModalInfoBySelectedClient = async (selectedClient: CompanyMinimalInfo): Promise<void> => {
    setLoading(true)
    setTabValues(clientTabValues)
    const selectedClientInfo = await getClientInfoByIdentifier(selectedClient.identifier)
    setClientInfo(selectedClientInfo)
    setLoading(false)
  }

  const getPartnerInfoByIdentifier = async (identifier: string): Promise<PartnerResponse> => {
    return getPartnerByIdentifier(identifier)
  }

  const setModalInfoBySelectedPartner = async (selectedPartner: CompanyMinimalInfo): Promise<void> => {
    setLoading(true)
    setTabValues(partnerTabValues)
    const selectedPartnerInfo = await getPartnerInfoByIdentifier(selectedPartner.identifier)
    setPartnerInfo(selectedPartnerInfo)
    setLoading(false)
  }

  const handleInit = async (): Promise<void> => {
    if (open && client) {
      await setModalInfoBySelectedClient(client)
    }
    if (open && partner) {
      await setModalInfoBySelectedPartner(partner)
    }
  }

  React.useEffect(() => {
    handleInit()
  }, [open, client, partner])

  React.useEffect(() => {
    setSelectedTab('Dados Primários')
  }, [handleModalClose])

  const handleTabChange = (_event: React.SyntheticEvent, newValue: string): void => {
    setSelectedTab(newValue)
  }

  const handleEditButton = (): void => {
    if (client) {
      navigate(`/main/clients/form/${client.identifier}`)
    } else if (partner) {
      navigate(`/main/partners/form/${partner.identifier}`)
    }
  }

  return (
    <Dialog
      open={open}
      onClose={handleModalClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      maxWidth="md"
    >
      <TabContext value={selectedTab}>
        {loading && tabValues.length > 0 ? (
          <Loading isShowLoading />
        ) : (
          <>
            <DialogTitle sx={{ backgroundColor: '#43b849', color: 'white' }}>{companyName}</DialogTitle>
            <DialogContent dividers sx={{ width: '900px' }}>
              <Box sx={{ typography: 'body1' }}>
                <TabList onChange={handleTabChange} aria-label="view-info-tabs" variant="scrollable">
                  {tabValues!.map((tab) => (
                    <Tab key={`tab-${tab.label}`} label={tab.label} value={tab.value} />
                  ))}
                </TabList>
              </Box>
              <section style={{ height: '400px' }}>
                <TabPanel value="Dados Primários">
                  {client && <ClientPrimaryData client={clientInfo} />}
                  {partner && <PartnerPrimaryData partner={partnerInfo} />}
                </TabPanel>
                <TabPanel value="Dados do contrato">
                  <ClientContractData client={clientInfo} />
                </TabPanel>
                <TabPanel value="Dados Técnicos">
                  <PartnerTechnicalData partner={partnerInfo} />
                </TabPanel>
                <TabPanel value="Contatos">
                  {client && <ClientContactData client={clientInfo} />}
                  {partner && <PartnerContacts partner={partnerInfo} />}
                </TabPanel>
                <TabPanel value="Observações">
                  <ClientObservations clientIdentifier={client?.identifier as string} />
                </TabPanel>
              </section>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditButton}>Editar</Button>
              <Button onClick={handleModalClose}>Fechar</Button>
            </DialogActions>
          </>
        )}
      </TabContext>
    </Dialog>
  )
}

export default ViewInfoModal

import React from 'react'
import * as yup from 'yup'
import { useForm, type SubmitHandler } from 'react-hook-form'

import AppContext, { AppContextType } from '../../../../AppContext'
import MtrModel from '../../../../models/MtrModel/MtrModel'
import { Select2Options } from '../../../../components/Form/Select/Select2'
import { defaultBreadCrumbItems } from '../../../../components/BreadCrumb/BreadCrumb'
import MtrModelMainForm from '../MtrModelMainForm'
import useYupValidationResolver from '../../../../utils/yup-validator-resolver'
import { saveMtrModel } from '../../../../services/MtrModel/MtrModelService'
import setRequestData from '../utils/setRequestData'

const validationSchema = yup.object({
  name: yup.string().required('campo obrigatório'),
  generatorIdentifier: yup.string().required('campo obrigatório'),
  receiverIdentifier: yup.string().required('campo obrigatório'),
  transporterIdentifier: yup.string().required('campo obrigatório'),
  vehicleIdentifier: yup.string(),
  driverIdentifier: yup.string(),
  mtrObservation: yup.string(),
  wasteModel: yup.array().required('campo obrigatório').min(1),
})

const NewModelForm: React.FC = () => {
  const resolver = useYupValidationResolver(validationSchema)
  const useFormObject = useForm<MtrModel>({
    resolver,
  })
  const [contractsMap, setContractsMap] = React.useState<Select2Options[]>([])
  const [vehiclesMap, setVehiclesMap] = React.useState<Select2Options[]>([])
  const [companiesMap, setCompaniesMap] = React.useState<Select2Options[]>([])
  const { setTitle, setCustomHeaderContent, setIsShowLoading, showAlert, setItemsBreadCrumb } = React.useContext(
    AppContext as React.Context<AppContextType>
  )

  const handleInit = React.useCallback(async (): Promise<void> => {
    setItemsBreadCrumb([
      ...defaultBreadCrumbItems,
      { label: 'Listar modelos de MTR', path: '/main/mtrmodel/list' },
      { label: 'Novo modelo MTR', path: '/main/mtrmodel/form' },
    ])
    setTitle('Novo Modelo de MTR')
    setCustomHeaderContent(<div />)
  }, [setItemsBreadCrumb, setCustomHeaderContent, setTitle])

  const onSubmit: SubmitHandler<MtrModel> = (data: MtrModel) => {
    setIsShowLoading(true)
    const requestData = setRequestData(data)
    saveMtrModel(requestData)
      .then(() => {
        showAlert('success', 'Modelo criado com sucesso.')
      })
      .catch(() => {
        showAlert('error', 'Erro ao criar modelo. Tente novamente mais tarde.')
      })
      .finally(() => {
        setIsShowLoading(false)
      })
  }
  return (
    <MtrModelMainForm
      contractsMap={contractsMap}
      setContractsMap={setContractsMap}
      vehiclesMap={vehiclesMap}
      setVehiclesMap={setVehiclesMap}
      companiesMap={companiesMap}
      setCompaniesMap={setCompaniesMap}
      handleInit={handleInit}
      onSubmit={onSubmit}
      useFormObject={useFormObject}
    />
  )
}

export default NewModelForm

import Popover from '@mui/material/Popover'
import Typography from '@mui/material/Typography'
import React from 'react'

export type EditDeletePopoverProps = {
  open: boolean
  item: any
  editFunction?: (item: any) => void
  deleteFunction?: (item: any) => void
  handleClose: () => void
  anchor: any
}

const EditDeletePopover: React.FC<EditDeletePopoverProps> = (props: EditDeletePopoverProps) => {
  const { open, editFunction, deleteFunction, item, handleClose, anchor } = props

  return (
    <Popover
      sx={{
        '.MuiPaper-root': {
          boxShadow: 'none',
          border: '1px solid #00000040',
          borderRadius: '10px',
        },
      }}
      open={open}
      onClose={handleClose}
      anchorEl={anchor}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
    >
      {editFunction && (
        <Typography sx={{ cursor: 'pointer', margin: '10px' }} onClick={() => editFunction(item)}>
          Editar
        </Typography>
      )}
      {deleteFunction && (
        <>
          <hr style={{ border: '1px solid #E9E9E8' }} />
          <Typography sx={{ cursor: 'pointer', margin: '10px' }} onClick={() => deleteFunction(item)}>
            Excluir
          </Typography>
        </>
      )}
    </Popover>
  )
}

export default EditDeletePopover

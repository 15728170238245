import axios, { AxiosResponse } from 'axios'
import getEndpointUrl from '../../utils/url-resolver'
import { ClientContract } from '../../models/Client/ClientContract'

const baseUrl = `${getEndpointUrl('company')}/v1/customers`

const createClientContract = async (
  clientContract: ClientContract,
  customerIdentifier: string
): Promise<ClientContract> => {
  return axios.post(`${baseUrl}/${customerIdentifier}/contracts`, clientContract).then((response) => response.data)
}

const updateClientContract = async (
  clientContract: ClientContract,
  customerIdentifier: string
): Promise<ClientContract> => {
  return axios.put(`${baseUrl}/${customerIdentifier}/contracts`, clientContract).then((response) => response.data)
}

export { createClientContract, updateClientContract }

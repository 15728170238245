import axios from 'axios'

import MtrModel, { MtrModelMinimalResponse, MtrModelResponse } from '../../models/MtrModel/MtrModel'
import getEndpointUrl from '../../utils/url-resolver'

const baseUrl = `${getEndpointUrl('company')}/v1/mtr-models`

const getModelsByCustomerIdentifier = (customerIdentifier: string): Promise<MtrModelMinimalResponse[]> => {
  return axios
    .get<MtrModelMinimalResponse[]>(`${baseUrl}/customer/${customerIdentifier}`)
    .then((response) => response.data)
}

const getMtrModelByIdentifier = (identifier: string): Promise<MtrModelResponse> => {
  return axios.get<MtrModelResponse>(`${baseUrl}/${identifier}`).then((response) => response.data)
}

const saveMtrModel = (model: MtrModel): Promise<void> => {
  return axios.post(`${baseUrl}`, model)
}

const editMtrModel = (modelIdentifier: string, model: MtrModel): Promise<void> => {
  return axios.put(`${baseUrl}/${modelIdentifier}`, model)
}

const deleteMtrModelByIdentifier = (modelIdentifier: string): Promise<void> => {
  return axios.delete(`${baseUrl}/${modelIdentifier}`)
}

export {
  getModelsByCustomerIdentifier,
  saveMtrModel,
  editMtrModel,
  deleteMtrModelByIdentifier,
  getMtrModelByIdentifier,
}

import { Card, Chip, FormLabel, Grid, InputAdornment, Switch } from '@mui/material'
import React, { SyntheticEvent } from 'react'
import { Controller, UseFormReturn } from 'react-hook-form'
import { ClientFormType } from '../../../../../models/Company/Company'
import InputSelect2, { Select2Options } from '../../../../Form/Select/Select2'
import InputText from '../../../../Form/Input/Input'
import { useStyles } from '../../../CompanyFormStyle'
import PopOverMessage from '../../../../PopOver/PopOver'
import { getChipColor, getChipLabel } from '../../../../../utils/getChipTagInfo'

interface ClientContractDataProps {
  form: UseFormReturn<ClientFormType>
}

const ClientContractData: React.FC<ClientContractDataProps> = ({ form }) => {
  const { control, setValue, getValues, formState, watch } = form
  const classes = useStyles()

  const purchasedPlanOptions = [
    { value: 'ATERRO ZERO', label: 'Aterro Zero' },
    { value: 'GESTÃO ECO', label: 'Gestão Eco' },
    { value: 'GESTÃO VERDE', label: 'Gestão Verde' },
  ]

  /* eslint-disable no-param-reassign */
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    onChange: (value: string) => void
  ): void => {
    const { value } = event.target
    const newValue = value.replace(/^0+(\d+)/, '$1')
    onChange(newValue)
    event.target.value = newValue
  }

  const handlePurchasedPlanChange = (event: SyntheticEvent<Element, Event>, value: Select2Options | null): void => {
    setValue('purchasedPlan', String(value?.value))
  }

  return (
    <Card className="form-card" variant="outlined">
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <h2 className={classes.tabTitle}>Dados do contrato</h2>
        {watch('expirationStatus') !== '' && (
          <Grid item xs={12} sm={6} key="expirationStatus">
            <Chip label={getChipLabel(watch('expirationStatus'))} color={getChipColor(watch('expirationStatus'))} />
          </Grid>
        )}
      </div>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} key="status">
          <FormLabel component="label" className={classes.labelStyle} sx={{ display: 'flex', flexDirection: 'column' }}>
            Status
            <Controller
              name="status"
              control={control}
              render={({ field }) => (
                <Switch
                  focusVisibleClassName=".Mui-focusVisible"
                  className={classes.switchStyle}
                  disableRipple
                  checked={field.value}
                  {...field}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="purchasedPlan">
          <FormLabel component="label" className={classes.labelStyle}>
            Plano adquirido
            <Controller
              name="purchasedPlan"
              control={control}
              render={({ field }) => (
                <InputSelect2
                  id="purchasedPlan"
                  label=""
                  options={purchasedPlanOptions}
                  errorText={formState.errors.purchasedPlan?.message}
                  value={getValues('purchasedPlan')}
                  onChange={handlePurchasedPlanChange}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="cashbackPercentage">
          <FormLabel component="label" className={classes.labelStyle}>
            Cashback
            <Controller
              name="cashbackPercentage"
              control={control}
              render={({ field }) => (
                <InputText
                  id="cashbackPercentage"
                  label=""
                  InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                  errorText={formState.errors.cashbackPercentage?.message}
                  type="number"
                  {...field}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="paymentTime">
          <FormLabel component="label" className={classes.labelStyle}>
            Prazo para pagamento
            <Controller
              name="paymentTime"
              control={control}
              render={({ field }) => (
                <InputText
                  id="paymentTime"
                  label=""
                  InputProps={{ endAdornment: <InputAdornment position="end">dias</InputAdornment> }}
                  errorText={formState.errors.paymentTime?.message}
                  type="number"
                  value={getValues('paymentTime')}
                  onChange={(e) => handleInputChange(e, field.onChange)}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="minimumValue">
          <FormLabel component="label" className={classes.labelStyle}>
            Valor Mínimo para Cashback
            <Controller
              name="minimumValue"
              control={control}
              defaultValue={0.0}
              render={({ field }) => (
                <InputText
                  id="minimumValue"
                  label=""
                  InputProps={{ startAdornment: <InputAdornment position="start">R$</InputAdornment> }}
                  errorText={formState.errors.minimumValue?.message}
                  type="number"
                  onChange={(e) => handleInputChange(e, field.onChange)}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="expirationDate">
          <FormLabel component="label" className={classes.labelStyle}>
            Data de Expiração
            <Controller
              name="expirationDate"
              control={control}
              render={({ field }) => (
                <InputText
                  label=""
                  id="expirationDate"
                  name="expirationDate"
                  errorText={formState.errors?.expirationDate?.message}
                  type="date"
                  value={getValues('expirationDate')}
                  onChange={({ target }) => setValue('expirationDate', target.value)}
                />
              )}
            />
          </FormLabel>
        </Grid>
      </Grid>
    </Card>
  )
}

export default ClientContractData

import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import CloseIcon from '@mui/icons-material/Close'
import useStyles from './DeleteConfirmationStyle'

export type DeleteItemText = {
  title: string
  item: string
  name: string
}

type DeleteConfirmationProps = {
  handleConfirmDelete: () => void
  isShowDeleteConfirmation: boolean
  handleClose: () => void
  deleteItemTexts: DeleteItemText
}

const DeleteConfirmation: React.FC<DeleteConfirmationProps> = ({
  handleConfirmDelete,
  isShowDeleteConfirmation,
  handleClose,
  deleteItemTexts,
}) => {
  const classes = useStyles()

  return (
    <Dialog
      open={isShowDeleteConfirmation}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <article className={classes.deleteBox}>
        <section className={classes.titleSection}>
          <DialogTitle>{deleteItemTexts.title}</DialogTitle>
          <button type="button" className={classes.closeDialogButton} onClick={handleClose}>
            <CloseIcon color="disabled" fontSize="small" />
          </button>
        </section>
        <p className={classes.deleteTextBox}>
          Tem certeza que deseja excluir {deleteItemTexts.item}
          <br />
          {deleteItemTexts.name}?
        </p>
        <section className={classes.deleteButtonsBox}>
          <button
            className={[classes.deleteButton, classes.deleteButtonYes].join(' ')}
            type="button"
            onClick={handleConfirmDelete}
          >
            Sim
          </button>
          <button
            className={[classes.deleteButton, classes.deleteButtonNo].join(' ')}
            type="button"
            onClick={handleClose}
          >
            Não
          </button>
        </section>
      </article>
    </Dialog>
  )
}

export default DeleteConfirmation

import React from 'react'
import IconButton from '@mui/material/IconButton'
import { useNavigate } from 'react-router-dom'
import Card from '@mui/material/Card'
import DataTable from '../../../components/DataTable/DataTable'
import { OperationListBranch } from '../../../models/Company/Operation'
import AppContext, { AppContextType } from '../../../AppContext'
import InputSelect2, { Select2Options } from '../../../components/Form/Select/Select2'
import { getPartnersByCustomer } from '../../../services/Contract/ContractService'
import { getAllCustomers } from '../../../services/Companies/CompaniesService'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'
import { featureAccessCheck } from '../../../utils/AccessPermissionsCheck'
import Icons from '../../../assets/icons/svgIcons'
import { CompanyMinimalInfo } from '../../../models/Company/Company'
import getInitialSelectedCompany, { getCompanyByIdentifier } from '../../../utils/getInitialSelectedCompany'

const ContractList: React.FC = () => {
  const navigate = useNavigate()
  const contractsMap = [
    { key: 'partner', value: 'Parceiro' },
    { key: 'cnpj', value: 'CNPJ' },
  ]
  const { setTitle, setCustomHeaderContent, showAlert, setItemsBreadCrumb, defaultCompany, setDefaultCompany } =
    React.useContext(AppContext as React.Context<AppContextType>)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [customers, setCustomers] = React.useState<CompanyMinimalInfo[]>([])
  const [customerPartners, setCustomerPartners] = React.useState<{ partner: string; cnpj: string }[]>([])
  const [customersMap, setCustomersMap] = React.useState<{ value: string; label: string }[]>([])
  const [selectedCompany, setSelectedCompany] = React.useState<string>('')

  const handleClickNew = React.useCallback((): void => {
    navigate('/main/contract/form')
  }, [navigate])

  const customHeaderContent = React.useMemo(
    () =>
      featureAccessCheck('contract-write') ? (
        <IconButton aria-label="Novo contrato" onClick={handleClickNew} size="large">
          <Icons.Add fill="white" />
        </IconButton>
      ) : (
        <div />
      ),
    [handleClickNew]
  )

  const setInitialSelectedCustomer = (customersInfo: CompanyMinimalInfo[]): void => {
    const initialSelectedCustomer = getInitialSelectedCompany(customersInfo, defaultCompany)
    setSelectedCompany(initialSelectedCustomer.identifier)
  }

  const handleCustomersData = (): void => {
    try {
      getAllCustomers().then((customersInfo) => {
        setCustomers(customersInfo)
        const mappedCustomers = customersInfo.map((customer) => {
          return {
            value: customer.identifier,
            label: customer.name,
          }
        })
        setCustomersMap(mappedCustomers)
        setInitialSelectedCustomer(customersInfo)
      })
    } catch {
      showAlert('error', 'Erro ao carregar Clientes.')
    } finally {
      setLoading(false)
    }
  }

  const handleInit = React.useCallback((): void => {
    setLoading(true)
    setTitle('Listar Contratos')
    setCustomHeaderContent(customHeaderContent)
    handleCustomersData()
  }, [customHeaderContent, setCustomHeaderContent, setTitle, showAlert])

  React.useEffect(() => {
    handleInit()
  }, [handleInit])

  React.useEffect(() => {
    if (!selectedCompany) return
    setLoading(true)
    getPartnersByCustomer(selectedCompany)
      .then((data) => {
        const map = data.map((partner) => {
          return {
            partner: partner.name,
            cnpj: partner.cnpj,
          }
        })
        setCustomerPartners(map)
      })
      .catch(() => {
        setCustomerPartners([])
        showAlert('info', 'Cliente não possui contratos cadastrados.')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [selectedCompany])

  React.useEffect(() => {
    setItemsBreadCrumb([...defaultBreadCrumbItems, { label: 'Listar contratos', path: '/main/contract/list' }])
  }, [setItemsBreadCrumb])

  const setDefaultCompanyInfo = (customerIdentifier: string): void => {
    const customerInfo = getCompanyByIdentifier(customers, customerIdentifier)
    setDefaultCompany(customerInfo)
  }

  const onChangeCompany = (event: React.SyntheticEvent, value: Select2Options | null): void => {
    const identifierCustomer = value?.value as string
    setSelectedCompany(identifierCustomer)
    setDefaultCompanyInfo(identifierCustomer)
  }

  return (
    <>
      {featureAccessCheck('contract-filter') && (
        <Card className="card-filters" variant="outlined">
          <InputSelect2
            id="customer"
            label="Cliente"
            value={selectedCompany}
            options={customersMap}
            onChange={onChangeCompany}
          />
        </Card>
      )}
      <div className={featureAccessCheck('contract-filter') ? 'filter--open' : 'filter--closed'}>
        <DataTable
          data={customerPartners}
          dataMap={contractsMap}
          loading={loading}
          // actions={[{ label: 'editar', icon: Icons.Edit, fn: handleEdit }]}
        />
      </div>
    </>
  )
}

export default ContractList

import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import AppContext, { AppContextType } from '../../../AppContext'
import DataTable from '../../../components/DataTable/DataTable'
import Employee, { EmployeeMinimalResponse } from '../../../models/Employee/employee'
import { deleteEmployee, getEmployeesByCustomerIdentifier } from '../../../services/Employee/EmployeeService'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'
import InputSelect2, { Select2Options } from '../../../components/Form/Select/Select2'
import Icons from '../../../assets/icons/svgIcons'
import EditDeletePopover from '../../../components/PopOver/EditDeletePopover'
import DeleteConfirmation, { DeleteItemText } from '../../../components/DeleteConfirmation/DeleteConfirmation'
import { featureAccessCheck, iconAccessCheck } from '../../../utils/AccessPermissionsCheck'
import { ApplicationColors } from '../../../utils/applicationColors'
import { getAllClients } from '../../../services/Client/ClientService'
import type { CompanyMinimalInfo } from '../../../models/Company/Company'
import getInitialSelectedCompany, { getCompanyByIdentifier } from '../../../utils/getInitialSelectedCompany'

const EmployeeList: React.FC = () => {
  const {
    setTitle,
    setCustomHeaderContent,
    showAlert,
    setIsShowLoading,
    setItemsBreadCrumb,
    defaultCompany,
    setDefaultCompany,
  } = React.useContext(AppContext as React.Context<AppContextType>)
  const [customers, setCustomers] = React.useState<CompanyMinimalInfo[]>([])
  const [customersMap, setCustomersMap] = React.useState<{ value: string; label: string }[]>([])
  const [selectedCustomer, setSelectedCustomer] = React.useState<string>('')
  const [employees, setEmployees] = React.useState<EmployeeMinimalResponse[]>([])
  const [loading, setLoading] = React.useState<boolean>(false)
  const [currentDeleteItem, setCurrentDeleteItem] = React.useState<EmployeeMinimalResponse | null>(null)
  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = React.useState<boolean>(false)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [anchor, setAnchor] = React.useState<any>(null)
  const [itemToChange, setItemToChange] = React.useState<Employee>()
  const [deleteItemTexts, setDeleteItemTexts] = React.useState<DeleteItemText>({ title: '', item: '', name: '' })
  const navigate = useNavigate()
  const employeesMap = [{ key: 'name', value: 'Nome' }]

  const handleClickNew = React.useCallback((): void => {
    navigate('/main/employee/form')
  }, [navigate])

  const handleEdit = (item: EmployeeMinimalResponse): void => {
    navigate(`/main/employee/form/${item.id}`)
  }

  const openDeleteConfirmation = (item: EmployeeMinimalResponse): void => {
    setIsShowDeleteConfirmation(true)
    setDeleteItemTexts({
      title: 'Excluir funcionário',
      item: 'o funcionário',
      name: item.name,
    })
    setCurrentDeleteItem(item)
  }

  const handleDelete = (item: EmployeeMinimalResponse): void => {
    setIsShowLoading(true)
    deleteEmployee(item.id as number)
      .then(() => {
        setEmployees(employees.filter((p) => p.id !== item.id))
        showAlert('success', 'Funcionário excluído com sucesso.')
        setIsShowLoading(false)
      })
      .catch(() => {
        showAlert('error', 'Erro ao excluir funcionário.')
        setIsShowLoading(false)
      })
  }

  const handleDeleteConfirmationClose = (): void => {
    setIsShowDeleteConfirmation(false)
  }

  const handleConfirmDelete = (): void => {
    if (currentDeleteItem) {
      handleDelete(currentDeleteItem)
    }
    setCurrentDeleteItem(null)
    handleDeleteConfirmationClose()
    setIsOpen(false)
  }

  const customHeaderContent: JSX.Element = React.useMemo(
    () =>
      featureAccessCheck('employee-write') && (
        <IconButton aria-label="Novo funcionário" onClick={handleClickNew} size="large">
          <Icons.Add fill="white" />
        </IconButton>
      ),
    [handleClickNew]
  ) as JSX.Element

  const setEmployeesList = (customerIdentifier: string): void => {
    setLoading(true)
    try {
      getEmployeesByCustomerIdentifier(customerIdentifier).then((employeesData: EmployeeMinimalResponse[]) => {
        setEmployees(employeesData)
      })
    } catch {
      showAlert('error', 'Erro ao carregar Funcionários. Tente novamente mais tarde.')
    } finally {
      setLoading(false)
    }
  }

  const setInitialSelectedCustomer = (customersInfo: CompanyMinimalInfo[]): void => {
    const initialSelectedCustomer = getInitialSelectedCompany(customersInfo, defaultCompany)
    setSelectedCustomer(initialSelectedCustomer.identifier)
    setEmployeesList(initialSelectedCustomer.identifier)
  }

  const setCustomersData = (): void => {
    try {
      const customersOptions: { value: string; label: string }[] = []
      getAllClients().then((customersInfo: CompanyMinimalInfo[]) => {
        setCustomers(customersInfo)
        customersInfo.forEach((customer) => {
          customersOptions.push({ value: customer.identifier, label: customer.name })
        })
        setCustomersMap(customersOptions)
        setInitialSelectedCustomer(customersInfo)
      })
    } catch {
      showAlert('error', 'Erro ao carregar Empresas. Tente novamente mais tarde.')
    }
  }

  const handleInit = React.useCallback((): void => {
    setIsShowLoading(true)
    setTitle('Listar Funcionários')
    setCustomHeaderContent(customHeaderContent)

    setCustomersData()

    setIsShowLoading(false)
  }, [customHeaderContent, setCustomHeaderContent, setTitle, showAlert])

  const handleMoreOptions = (item: Employee, index?: number, anchorEl?: EventTarget & HTMLButtonElement): void => {
    setIsOpen(true)
    setAnchor(anchorEl)
    setItemToChange(item)
  }

  const handleClose = (): void => {
    setIsOpen(false)
  }

  React.useEffect(() => {
    handleInit()
  }, [handleInit])

  React.useEffect(() => {
    setItemsBreadCrumb([...defaultBreadCrumbItems, { label: 'Listar funcionários', path: '/main/employee/list' }])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setDefaultCompanyInfo = (customerIdentifier: string): void => {
    const selectedCustomerInfo = getCompanyByIdentifier(customers, customerIdentifier)
    setDefaultCompany(selectedCustomerInfo)
  }

  const handleChangeCustomer = (event: React.SyntheticEvent, value: Select2Options | null): void => {
    setLoading(true)
    const customerIdentifier = value?.value as string
    setDefaultCompanyInfo(customerIdentifier)
    setSelectedCustomer(customerIdentifier)
    setEmployeesList(customerIdentifier)
  }

  return (
    <>
      <DeleteConfirmation
        handleConfirmDelete={handleConfirmDelete}
        isShowDeleteConfirmation={isShowDeleteConfirmation}
        handleClose={handleDeleteConfirmationClose}
        deleteItemTexts={deleteItemTexts}
      />

      <Card className="card-filters" variant="outlined">
        <InputSelect2
          id="branches"
          label="Empresa"
          value={selectedCustomer}
          options={customersMap}
          onChange={handleChangeCustomer}
        />
      </Card>

      <div className="filter--open">
        <DataTable
          data={employees}
          dataMap={employeesMap}
          loading={loading}
          actions={
            iconAccessCheck('employee-edit') || iconAccessCheck('employee-delete')
              ? [
                  {
                    label: 'Opções',
                    icon: <Icons.MoreVertical fill={ApplicationColors.primaryColor} />,
                    fn: handleMoreOptions,
                  },
                ]
              : []
          }
        />

        <EditDeletePopover
          editFunction={iconAccessCheck('employee-edit') ? handleEdit : undefined}
          deleteFunction={iconAccessCheck('employee-delete') ? openDeleteConfirmation : undefined}
          open={isOpen}
          item={itemToChange}
          handleClose={handleClose}
          anchor={anchor}
        />
      </div>
    </>
  )
}

export default EmployeeList

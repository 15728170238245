import SearchIcon from '@mui/icons-material/Search'
import React from 'react'
import { NavLink } from 'react-router-dom'

import clsx from 'clsx'
import InputText from '../Form/Input/Input'
import { signOut } from '../../services/Auth/TokenService'
import { getUserAllowedPagesInfo } from '../../utils/AccessPermissionsCheck'
import allPagesInfo, { IPageInfo } from '../../models/Pages/AllPagesInfo'
import Icons from '../../assets/icons/svgIcons'
import useStyles from './MenuStyles'

const Menu: React.FC = () => {
  const classes = useStyles()
  const [menuOptions, setMenuOptions] = React.useState<IPageInfo[]>([])
  const [filteredMenuOptions, setFilteredMenuOptions] = React.useState<IPageInfo[]>([])

  React.useEffect(() => {
    const menu = getUserAllowedPagesInfo().filter((page) => page.feature.includes('read') && !page.link.match(/\\d\+/))
    setMenuOptions(menu)
    setFilteredMenuOptions(menu)
  }, [])

  const handleMenuSearch = (event: React.FormEvent<HTMLInputElement>): void => {
    const search = (event.target as HTMLInputElement).value.toLocaleLowerCase()
    setFilteredMenuOptions(menuOptions.filter((option) => option.title.toLocaleLowerCase().includes(search)))
  }

  const handleLogout = (): void => {
    signOut()
  }

  return (
    <>
      <div className={classes.searchBox}>
        <SearchIcon />
        <InputText id="search" placeholder="Procurar conversa" onInput={handleMenuSearch} />
      </div>
      <ul className="menuMobileList">
        {filteredMenuOptions.map((h) => (
          <li key={h.title} className={classes.menuMobileItem}>
            <NavLink
              className={({ isActive }) =>
                isActive ? clsx(classes.menuMobileLink, classes.active) : classes.menuMobileLink
              }
              to={h.link}
            >
              {h.picture && <h.picture height={24} width={24} fill="black" />}
              <div className={classes.menuMobileItemInfo}>
                <h6>{h.title}</h6>
                <p>{h.description}</p>
              </div>
            </NavLink>
          </li>
        ))}
        <li key="sair" className={classes.menuMobileItem}>
          <button type="button" className={classes.menuMobileLink} onClick={handleLogout}>
            <Icons.Logout />
            <div className={classes.menuMobileItemInfo}>
              <h6>Sair</h6>
              <p>Sair do Sistema</p>
            </div>
          </button>
        </li>
      </ul>
    </>
  )
}

export default Menu

import React, { ReactElement } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { Chip } from '@mui/material'
import { CompanyFormType } from '../../../models/Company/Company'
import Icons from '../../../assets/icons/svgIcons'
import useStyles from './Styles/InputFileStyles'
import { documentsOptionsList } from '../../../models/Company/FormOptions/DocumentsOptions'

type SingleFileChangeHandler = (file: File | undefined) => void
type NamedFileChangeHandler = (documentName: string, file: File | undefined) => void

interface InputFileProps {
  id: string
  file?: File
  fileLink: string
  documentName: string
  isPartnerDoc: boolean
  onChange: SingleFileChangeHandler | NamedFileChangeHandler
}

const InputFile: React.FC<InputFileProps> = ({ id, file, fileLink, documentName, isPartnerDoc, onChange }) => {
  const inputStyles = useStyles()

  const renderChosenFile = (fileParam: File): ReactElement => {
    return (
      <div className={inputStyles.fileNameContainer}>
        <Chip
          label={fileParam.name || documentName}
          sx={{ backgroundColor: '#E3F6E8', color: '#1A1C18', margin: '2px' }}
        />
      </div>
    )
  }

  const renderSavedFile = (savedFileLink: string): ReactElement => {
    const docLabelName = documentsOptionsList.find((doc) => doc.value === documentName)?.label
    return (
      <div className={inputStyles.fileNameContainer}>
        <a href={savedFileLink} target="_blank" rel="noreferrer" className={inputStyles.savedFileLink}>
          <Chip label={`${docLabelName} Salva`} sx={{ backgroundColor: '#E3F6E8', color: '#1A1C18', margin: '2px' }} />
        </a>
        <Icons.Download className={inputStyles.iconStyle} onClick={() => window.open(savedFileLink, '_blank')} />
      </div>
    )
  }

  const renderFileName = (): ReactElement => {
    if (file) {
      return renderChosenFile(file)
    }
    if (fileLink) {
      return renderSavedFile(fileLink)
    }
    return <p>Anexe um arquivo</p>
  }

  const handleDocumentFileChange = (event: React.SyntheticEvent<any>): void => {
    const target = event.target as HTMLInputElement
    if (isPartnerDoc) {
      ;(onChange as NamedFileChangeHandler)(documentName, target.files?.[0] || undefined)
    } else {
      ;(onChange as SingleFileChangeHandler)(target.files?.[0] || undefined)
    }
  }

  return (
    <div className={inputStyles.fileInputContainer}>
      {renderFileName()}
      <label htmlFor={id} className="upload-icon">
        <input id={id} type="file" style={{ display: 'none' }} onChange={(event) => handleDocumentFileChange(event)} />
        <Icons.Upload style={{ cursor: 'pointer' }} />
      </label>
    </div>
  )
}

export default InputFile

import Icons from '../../assets/icons/svgIcons'
import IconPicture from '../Icon/icon'

export interface IPageInfo {
  picture: IconPicture
  title: string
  link: string
  type: string
  feature: string
  description: string
  showSideBar: boolean
}

export interface AllowedActions {
  label: string
  icon: string
  fn: (item: any) => void
}

const idParamPattern = '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}' // eslint-disable-line no-useless-escape

const allPagesInfo: IPageInfo[] = [
  {
    picture: Icons.Home,
    title: 'Início',
    link: '/main/home',
    type: 'home',
    feature: 'home-read',
    description: 'Página de boas vindas do sistema',
    showSideBar: true,
  },
  {
    picture: undefined,
    title: 'Atualizações do Sistema',
    link: '/main/system-updates',
    type: 'system-updates',
    feature: 'basic-access',
    description: 'Listar atualizações do sistema',
    showSideBar: false,
  },
  {
    picture: Icons.MtrList,
    title: 'Manifestos de Transporte',
    link: '/main/gathering/list',
    type: 'gathering',
    feature: 'mtr-read',
    description: 'Listar os MTRs cadastrados',
    showSideBar: true,
  },
  {
    picture: undefined,
    title: 'Novo MTR',
    link: '/main/gathering/form',
    type: 'gathering',
    feature: 'mtr-write',
    description: 'Cadastrar um novo MTR',
    showSideBar: false,
  },
  {
    picture: Icons.Truck,
    title: 'Veículos',
    link: '/main/vehicle/list',
    type: 'vehicle',
    feature: 'vehicle-read',
    description: 'Listar seus veículos cadastrados',
    showSideBar: true,
  },
  {
    picture: undefined,
    title: 'Novo Veículo',
    link: '/main/vehicle/form',
    type: 'vehicle',
    feature: 'vehicle-write',
    description: 'Cadastrar um novo veículo',
    showSideBar: false,
  },
  {
    picture: undefined,
    title: 'Editar Veículo',
    link: `/main/vehicle/form/${idParamPattern}`,
    type: 'vehicle',
    feature: 'vehicle-edit',
    description: 'Editar um veículo',
    showSideBar: false,
  },
  {
    picture: Icons.Doc,
    title: 'Modelos de Manifesto',
    link: '/main/mtrmodel/list',
    type: 'mtrmodel',
    feature: 'mtrmodel-read',
    description: 'Listar modelos para o MTR',
    showSideBar: true,
  },
  {
    picture: undefined,
    title: 'Novo Modelo de Manifesto',
    link: '/main/mtrmodel/form',
    type: 'mtrmodel',
    feature: 'mtrmodel-write',
    description: 'Cadastrar um novo modelo de MTR',
    showSideBar: false,
  },
  {
    picture: undefined,
    title: 'Editar Modelo de Manifesto',
    link: `/main/mtrmodel/form/${idParamPattern}`,
    type: 'mtrmodel',
    feature: 'mtrmodel-edit',
    description: 'Editar um modelo de MTR',
    showSideBar: false,
  },
  {
    picture: Icons.Report,
    title: 'Relatórios',
    link: '/main/report/list',
    type: 'report',
    feature: 'report-read',
    description: 'Visualizar meus relatórios',
    showSideBar: true,
  },
  {
    picture: undefined,
    title: 'Novo Relatório',
    link: '/main/report/form',
    type: 'report',
    feature: 'report-write',
    description: 'Cadastrar um novo relatório',
    showSideBar: false,
  },
  {
    picture: undefined,
    title: 'Vizualizar Relatório',
    link: `/main/report/${idParamPattern}`,
    type: 'report',
    feature: 'report-read',
    description: 'Visualizar um relatório',
    showSideBar: false,
  },
  {
    picture: Icons.Plant,
    title: 'Documentação Ambiental',
    link: '/main/documentation/list',
    type: 'documentation',
    feature: 'documentation-read',
    description: 'Listar documentação ambiental',
    showSideBar: true,
  },
  {
    picture: Icons.Help,
    title: 'Ajuda',
    link: '/main/help/tutorial',
    type: 'help',
    feature: 'help-read',
    description: 'Ajuda para cadastrar IP na FEAM',
    showSideBar: false,
  },
  {
    picture: Icons.User,
    title: 'Usuários',
    link: '/main/user/list',
    type: 'user',
    feature: 'user-read',
    description: 'Listar os usuários do sistema',
    showSideBar: true,
  },
  {
    picture: undefined,
    title: 'Novo Usuário',
    link: '/main/user/form',
    type: 'user',
    feature: 'user-write',
    description: 'Cadastrar um novo usuário',
    showSideBar: false,
  },
  {
    picture: undefined,
    title: 'Editar Usuário',
    link: `/main/user/form/${idParamPattern}`,
    type: 'user',
    feature: 'user-edit',
    description: 'Editar um usuário',
    showSideBar: false,
  },
  {
    picture: Icons.Employee,
    title: 'Funcionários',
    link: '/main/employee/list',
    type: 'employee',
    feature: 'employee-read',
    description: 'Listar os funcionários cadastrados',
    showSideBar: true,
  },
  {
    picture: undefined,
    title: 'Novo Funcionário',
    link: `/main/employee/form/${idParamPattern}`,
    type: 'employee',
    feature: 'employee-edit',
    description: 'Editar um funcionário',
    showSideBar: false,
  },
  {
    picture: undefined,
    title: 'Editar Funcionário',
    link: '/main/employee/form',
    type: 'employee',
    feature: 'employee-write',
    description: 'Cadastrar um novo funcionário',
    showSideBar: false,
  },
  {
    picture: Icons.Company,
    title: 'Clientes',
    link: '/main/clients/list',
    type: 'companies',
    feature: 'company-read',
    description: 'Listar clientes cadastrados',
    showSideBar: true,
  },
  {
    picture: undefined,
    title: 'Novo Cliente',
    link: '/main/clients/form',
    type: 'companies',
    feature: 'company-write',
    description: 'Cadastrar um novo cliente',
    showSideBar: false,
  },
  {
    picture: undefined,
    title: 'Editar Cliente',
    link: `/main/clients/form/${idParamPattern}`,
    type: 'companies',
    feature: 'company-edit',
    description: 'Editar cliente',
    showSideBar: false,
  },
  {
    picture: Icons.Company,
    title: 'Parceiros',
    link: '/main/partners/list',
    type: 'companies',
    feature: 'company-read',
    description: 'Listar parceiros cadastrados',
    showSideBar: true,
  },
  {
    picture: undefined,
    title: 'Novo Parceiro',
    link: '/main/partners/form',
    type: 'companies',
    feature: 'company-write',
    description: 'Cadastrar um novo parceiro',
    showSideBar: false,
  },
  {
    picture: undefined,
    title: 'Editar parceiro',
    link: `/main/partners/form/${idParamPattern}`,
    type: 'companies',
    feature: 'company-edit',
    description: 'Editar parceiro',
    showSideBar: false,
  },
  {
    picture: Icons.Contract,
    title: 'Contratos',
    link: '/main/contract/list',
    type: 'contract',
    feature: 'contract-read',
    description: 'Listar os contratos do sistema',
    showSideBar: true,
  },
  {
    picture: undefined,
    title: 'Novo Contrato',
    link: '/main/contract/form',
    type: 'contract',
    feature: 'contract-write',
    description: 'Cadastrar um novo contrato',
    showSideBar: false,
  },
  {
    picture: Icons.Settings,
    title: 'Configurações',
    link: '/main/settings/panel',
    type: 'settings',
    feature: 'config-read',
    description: 'Configurações do sistema',
    showSideBar: false,
  },
  {
    picture: undefined,
    title: 'Mudar Senha',
    link: '/main/change-password',
    type: 'basic',
    feature: 'basic-access',
    description: 'Mudar sua própria senha',
    showSideBar: false,
  },
]

export default allPagesInfo

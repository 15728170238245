import Card from '@mui/material/Card'
import IconButton from '@mui/material/IconButton'
import React from 'react'
import { useNavigate } from 'react-router-dom'

import AppContext, { AppContextType } from '../../../AppContext'
import DataTable from '../../../components/DataTable/DataTable'
import { MtrModelMinimalResponse } from '../../../models/MtrModel/MtrModel'
import { deleteMtrModelByIdentifier, getModelsByCustomerIdentifier } from '../../../services/MtrModel/MtrModelService'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'
import { featureAccessCheck, iconAccessCheck } from '../../../utils/AccessPermissionsCheck'
import InputSelect2, { Select2Options } from '../../../components/Form/Select/Select2'
import Icons from '../../../assets/icons/svgIcons'
import EditDeletePopover from '../../../components/PopOver/EditDeletePopover'
import DeleteConfirmation, { DeleteItemText } from '../../../components/DeleteConfirmation/DeleteConfirmation'
import { ApplicationColors } from '../../../utils/applicationColors'
import { getAllClients } from '../../../services/Client/ClientService'
import type { CompanyMinimalInfo } from '../../../models/Company/Company'
import getInitialSelectedCompany, { getCompanyByIdentifier } from '../../../utils/getInitialSelectedCompany'

const MtrModelList: React.FC = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [itemToChange, setItemToChange] = React.useState<MtrModelMinimalResponse>()
  const [anchor, setAnchor] = React.useState<any>(null)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [currentDeleteItem, setCurrentDeleteItem] = React.useState<MtrModelMinimalResponse | null>(null)
  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = React.useState<boolean>(false)
  const [deleteItemTexts, setDeleteItemTexts] = React.useState<DeleteItemText>({ title: '', item: '', name: '' })
  const {
    userCompanies,
    setTitle,
    setCustomHeaderContent,
    showAlert,
    setIsShowLoading,
    setItemsBreadCrumb,
    defaultCompany,
    setDefaultCompany,
  } = React.useContext(AppContext as React.Context<AppContextType>)
  const [customers, setCustomers] = React.useState<CompanyMinimalInfo[]>([])
  const [customersOptions, setCustomersOptions] = React.useState<Select2Options[]>([])
  const [selectedCustomer, setSelectedCustomer] = React.useState<string>('')
  const [mtrModelList, setMtrModelList] = React.useState<MtrModelMinimalResponse[]>([])

  const navigate = useNavigate()
  const mtrModelsMap = [
    { key: 'name', value: 'Nome do Modelo' },
    { key: 'receiverName', value: 'Destinadora' },
    { key: 'transporterName', value: 'Transportadora' },
    { key: 'generatorName', value: 'Geradora' },
  ]
  const handleClickNew = React.useCallback((): void => {
    navigate('/main/mtrmodel/form')
  }, [navigate])

  const handleEdit = (item: MtrModelMinimalResponse): void => {
    navigate(`/main/mtrmodel/form/${item.identifier}`)
  }

  const openDeleteConfirmation = (item: MtrModelMinimalResponse): void => {
    setIsShowDeleteConfirmation(true)
    setDeleteItemTexts({
      title: 'Excluir modelo',
      item: 'o modelo',
      name: item.name,
    })
    setCurrentDeleteItem(item)
  }

  const handleDelete = (item: MtrModelMinimalResponse): void => {
    setIsShowLoading(true)
    deleteMtrModelByIdentifier(item.identifier)
      .then(() => {
        setMtrModelList(mtrModelList.filter((v) => v.identifier !== item.identifier))
        showAlert('success', 'Modelo excluído com sucesso.')
      })
      .catch(() => {
        showAlert('error', 'Erro ao excluir modelo.')
      })
      .finally(() => {
        setIsShowLoading(false)
      })
  }

  const handleDeleteConfirmationClose = (): void => {
    setIsShowDeleteConfirmation(false)
  }

  const handleConfirmDelete = (): void => {
    if (currentDeleteItem) {
      handleDelete(currentDeleteItem)
    }
    setCurrentDeleteItem(null)
    handleDeleteConfirmationClose()
    setIsOpen(false)
  }

  const customHeaderContent: JSX.Element = React.useMemo(
    () =>
      featureAccessCheck('mtrmodel-write') && (
        <IconButton aria-label="Novo modelo" onClick={handleClickNew} size="large">
          <Icons.Add fill="white" />
        </IconButton>
      ),
    [handleClickNew]
  ) as JSX.Element

  const getMtrModelsByCustomerIdentifier = (customerIdentifier: string): Promise<MtrModelMinimalResponse[]> => {
    return getModelsByCustomerIdentifier(customerIdentifier)
      .then((data) => {
        if (data && data.length > 0) return data
        showAlert('info', 'Não há modelos de MTR cadastrados para este cliente.')
        return [] as MtrModelMinimalResponse[]
      })
      .catch(() => {
        showAlert('error', 'Erro ao carregar modelos de MTR.')
        return [] as MtrModelMinimalResponse[]
      })
  }

  const setMtrModelListInfo = (modelList: MtrModelMinimalResponse[] | null): void => {
    if (modelList) {
      setMtrModelList(modelList)
    }
  }

  const setDefaultCompanyInfo = (customerIdentifier: string): void => {
    const selectedCustomerInfo = getCompanyByIdentifier(customers, customerIdentifier)
    setDefaultCompany(selectedCustomerInfo)
  }

  const handleChangeCustomer = async (event: React.SyntheticEvent, value: Select2Options | null): Promise<void> => {
    setLoading(true)
    const customerIdentifier = value?.value as string
    setSelectedCustomer(customerIdentifier)
    setDefaultCompanyInfo(customerIdentifier)
    const modelsList = await getMtrModelsByCustomerIdentifier(customerIdentifier)
    setMtrModelListInfo(modelsList)
    setLoading(false)
  }

  const getAllCustomers = (): Promise<CompanyMinimalInfo[] | null> => {
    return getAllClients()
      .then((clientsList) => {
        if (clientsList && clientsList.length > 0) return clientsList
        showAlert('info', 'Não há clientes cadastrados ou vinculados ao seu usário.')
        return null
      })
      .catch(() => {
        showAlert('error', 'Não foi posssível carregar a listagem de clientes.')
        return null
      })
  }

  const setCustomersInfo = (
    clientsList: CompanyMinimalInfo[] | null,
    initialSelectedCustomer: CompanyMinimalInfo
  ): void => {
    if (clientsList) {
      setCustomers(clientsList)
      const clientsOptions = clientsList.map((client) => {
        return {
          value: client.identifier,
          label: client.name,
        }
      })
      setCustomersOptions(clientsOptions)
      setSelectedCustomer(initialSelectedCustomer.identifier)
    }
  }

  const handleInit = React.useCallback(async (): Promise<void> => {
    setLoading(true)
    setTitle('Listar Modelos de MTR')
    setCustomHeaderContent(customHeaderContent)

    const customerList = await getAllCustomers()

    if (!customerList) {
      setLoading(false)
      return
    }
    const initialSelectedCustomer = getInitialSelectedCompany(customerList, defaultCompany)
    setCustomersInfo(customerList, initialSelectedCustomer)

    const modelsList = await getMtrModelsByCustomerIdentifier(initialSelectedCustomer.identifier)
    setMtrModelListInfo(modelsList)
    setLoading(false)
  }, [customHeaderContent, setCustomHeaderContent, setTitle, showAlert, userCompanies])

  React.useEffect(() => {
    handleInit().finally(() => setIsShowLoading(false))
  }, [])

  const handleClose = (): void => {
    setIsOpen(false)
  }

  const handleMoreOptions = (
    item: MtrModelMinimalResponse,
    index?: number,
    anchorEl?: EventTarget & HTMLButtonElement
  ): void => {
    setIsOpen(true)
    setAnchor(anchorEl)
    setItemToChange(item)
  }

  React.useEffect(() => {
    setItemsBreadCrumb([...defaultBreadCrumbItems, { label: 'Listar modelos de MTR', path: '/main/mtrmodel/list' }])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <DeleteConfirmation
        handleConfirmDelete={handleConfirmDelete}
        isShowDeleteConfirmation={isShowDeleteConfirmation}
        handleClose={handleDeleteConfirmationClose}
        deleteItemTexts={deleteItemTexts}
      />
      <Card className="card-filters" variant="outlined">
        <InputSelect2
          id="branches"
          label="Cliente"
          value={selectedCustomer}
          options={customersOptions}
          onChange={handleChangeCustomer}
        />
      </Card>
      <div className="filter--open">
        <DataTable
          data={mtrModelList}
          dataMap={mtrModelsMap}
          loading={loading}
          compact
          actions={
            iconAccessCheck('mtrmodel-edit') || iconAccessCheck('mtrmodel-delete')
              ? [
                  {
                    label: 'Opções',
                    icon: <Icons.MoreVertical fill={ApplicationColors.primaryColor} />,
                    fn: handleMoreOptions,
                  },
                ]
              : []
          }
        />
        <EditDeletePopover
          editFunction={iconAccessCheck('mtrmodel-edit') ? handleEdit : undefined}
          deleteFunction={iconAccessCheck('mtrmodel-delete') ? openDeleteConfirmation : undefined}
          open={isOpen}
          item={itemToChange}
          handleClose={handleClose}
          anchor={anchor}
        />
      </div>
    </>
  )
}

export default MtrModelList

import axios, { AxiosResponse } from 'axios'

import { ClientRequestI, ClientResponseI, ClientGreenMonitor } from '../../models/Client/Client'
import getEndpointUrl from '../../utils/url-resolver'
import { CompanyContactsInfo, type CompanyMinimalInfo } from '../../models/Company/Company'

const baseUrl = `${getEndpointUrl('company')}/v1/customers`

const getAllClients = (): Promise<CompanyMinimalInfo[]> =>
  axios.get<CompanyMinimalInfo[]>(`${baseUrl}/`).then((response) => response.data)

const getClientByIdentifier = async (identifier: string): Promise<ClientResponseI> => {
  return axios.get(`${baseUrl}/${identifier}`).then((response) => response.data)
}

const getGreenMonitorUrl = async (identifier: string): Promise<string> => {
  return axios.get(`${baseUrl}/${identifier}/green_monitor`).then((response) => response.data)
}

const getAllGreenMonitorUrl = async (): Promise<ClientGreenMonitor[]> => {
  return axios.get(`${baseUrl}/green_monitor`).then((response) => response.data)
}

const saveClient = async (client: ClientRequestI): Promise<ClientRequestI> => {
  return axios.post(`${baseUrl}/`, client).then((response) => response.data)
}

const updateClient = async (identifier: string, client: ClientRequestI): Promise<ClientRequestI> => {
  return axios.put(`${baseUrl}/${identifier}`, client).then((response) => response.data)
}

const deleteClient = (identifier: string): Promise<AxiosResponse> => axios.delete<void>(`${baseUrl}/${identifier}`)

const saveClienteFacade = async (identifier: string, file: File): Promise<ClientResponseI> => {
  const formData = new FormData()
  formData.append('file', file)
  return axios.post(`${baseUrl}/${identifier}/facade`, formData).then((response) => response.data)
}

const createClientContact = async (identifier: string, contact: CompanyContactsInfo): Promise<CompanyContactsInfo> => {
  return axios.post(`${baseUrl}/${identifier}/contacts`, contact).then((response) => response.data)
}

const updateClientContact = async (
  identifier: string,
  contactIdentifier: string,
  contact: CompanyContactsInfo
): Promise<CompanyContactsInfo> => {
  return axios.put(`${baseUrl}/${identifier}/contacts/${contactIdentifier}`, contact).then((response) => response.data)
}

export {
  getAllClients,
  getClientByIdentifier,
  getGreenMonitorUrl,
  getAllGreenMonitorUrl,
  saveClient,
  updateClient,
  deleteClient,
  saveClienteFacade,
  createClientContact,
  updateClientContact,
}

// import jwt_decode from 'jwt-decode';

import { BranchesInfo, CompanyInfo } from '../../models/Company/Company'
import { UserFullResponse } from '../../models/User/user'
import { getThisUser } from '../User/UserService'

const COMPANY_TOKEN_KEY = 'AuthTokenCompany'
const USER = 'User'

const signOut = (): void => {
  window.localStorage.removeItem(COMPANY_TOKEN_KEY)
  window.localStorage.removeItem(USER)
  window.location.href = `${window.location.protocol}//${window.location.host}/auth/login`
}

const saveUser = (user: UserFullResponse): void => {
  window.localStorage.removeItem(USER)
  window.localStorage.setItem(USER, JSON.stringify(user))
}

const getLoggedUser = (): UserFullResponse | null => {
  const user = JSON.parse(localStorage.getItem(USER) as string)
  return user ?? null
}

const getUserFeatures = (): string[] | null => {
  const user = getLoggedUser()
  if (user && user.cargos && user.cargos[0].features) {
    return user.cargos.flatMap((c) => c.features).map((f) => f.authority)
  }
  return null
}

const getRole = (): string | null => {
  const features = getUserFeatures()
  if (features && features.length > 0) {
    return features[0]
  }
  return null
}

const getUserCompanies = (): CompanyInfo[] => {
  return []
}
const getUserBranches = (): BranchesInfo[] => {
  const companies = getUserCompanies()
  const branches: BranchesInfo[] = []
  if (companies) {
    companies.forEach((company) => {
      if (company.branches && company.branches.length > 0) {
        company.branches.forEach((branch) => {
          branches.push(branch)
        })
      }
    })
  }
  return branches
}

const saveToken = (token: string): void => {
  window.localStorage.removeItem(COMPANY_TOKEN_KEY)
  window.localStorage.setItem(COMPANY_TOKEN_KEY, token)
}

const getTokenCompany = (): string | null => localStorage.getItem(COMPANY_TOKEN_KEY)

export {
  signOut,
  getRole,
  getTokenCompany,
  saveToken,
  saveUser,
  getLoggedUser,
  getUserFeatures,
  getUserCompanies,
  getUserBranches,
}

export const documentsOptionsList = [
  { label: 'Licença Ambiental', value: 'licencaAmbiental' },
  { label: 'DML', value: 'dml' },
  { label: 'CTF Ibama', value: 'ctfIbama' },
  { label: 'AVCB', value: 'avcb' },
  { label: 'Alvará de Funcionamento da Prefeitura', value: 'alvaraFuncionamento' },
  { label: 'Alvará da Vigilância Sanitária', value: 'alvaraVigilancia' },
  { label: 'Licença de Caçamba', value: 'licencaCacamba' },
  { label: 'Licença de veículos para transporte', value: 'licencaVeiculos' },
  { label: 'MOPP do Motorista', value: 'mopp' },
  { label: 'AATIPP', value: 'aatipp' },
]

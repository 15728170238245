import axios from 'axios'

import Report from '../../models/Report/report'
import getEndpointUrl from '../../utils/url-resolver'

const baseUrl = `${getEndpointUrl('company')}/v1/reports`

const getReportsByCustomerIdentifier = (customerIdentifier: string): Promise<Report[]> =>
  axios.get<Report[]>(`${baseUrl}/customer/${customerIdentifier}`).then((response) => response.data)

const getReportByIdentifier = (identifier: string): Promise<Report> =>
  axios.get<Report>(`${baseUrl}/${identifier}`).then((response) => response.data)

const createReport = (customerIdentifier: string, report: Report): Promise<void> =>
  axios.post(`${baseUrl}/${customerIdentifier}`, report)

const updateReport = (identifier: string, report: Report): Promise<void> =>
  axios.patch(`${baseUrl}/${identifier}`, report)

const deleteReport = (identifier: string): Promise<void> => axios.delete(`${baseUrl}/${identifier}`)

export { getReportsByCustomerIdentifier, getReportByIdentifier, createReport, deleteReport, updateReport }

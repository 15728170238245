/* eslint-disable no-param-reassign */
import axios from 'axios'
import React, { ReactElement } from 'react'
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'

import { AlertColor } from '@mui/material'
import AppContext from './AppContext'
import { useStyles } from './AppStyles'
import Alerts from './components/Alerts/Alerts'
import Header from './components/Header/Header'
import Loading from './components/Loading/Loading'
import SidebarV2 from './components/Sidebar/SidebarV2'
import ContractRoute from './routes/Contracts/ContractsRoute'
import EmployeeRoute from './routes/Employee/EmployeeRoute'
import GatheringRoute from './routes/Gathering/GatheringRoute'
import Home from './routes/Home/Home'
import MtrModelRoute from './routes/MtrModel/MtrModelRoute'
import NotFound from './routes/NotFound/NotFound'
import AccessDenied from './routes/AccessDenied/AccessDenied'
import UserRoute from './routes/User/UserRoute'
import VehicleRoute from './routes/Vehicles/VehicleRoute'
import { getTokenCompany, getUserBranches, getUserCompanies, signOut } from './services/Auth/TokenService'
import HelpRoute from './routes/Help/HelpRoute'
import ReportRoute from './routes/Report/ReportRoute'
import GatheringList from './routes/Gathering/GatheringList/GatheringList'
import GatheringForm from './routes/Gathering/GatheringForm/GatheringForm'
import MtrModelList from './routes/MtrModel/MtrModelList/MtrModelList'
import VehicleList from './routes/Vehicles/VehicleList/VehicleList'
import VehicleForm from './routes/Vehicles/VehicleForm/VehicleForm'
import EmployeeForm from './routes/Employee/EmployeeForm/EmployeeForm'
import EmployeeList from './routes/Employee/EmployeeList/EmployeeList'
import HelpPage from './routes/Help/HelpPage/HelpPage'
import ReportForm from './routes/Report/ReportForm/ReportForm'
import ReportList from './routes/Report/ReportList/ReportList'
import ReportViewer from './routes/Report/ReportViewer/ReportViewer'
import ContractForm from './routes/Contracts/ContractForm/ContractForm'
import ContractList from './routes/Contracts/ContractList/ContractList'
import UserForm from './routes/User/UserForm/UserForm'
import UserList from './routes/User/UserList/UserList'
import Documentation from './routes/Documentation/Documentation/Documentation'
import HomeRoute from './routes/Home/HomeRoute'
import { getPathname } from './utils/url'
import { BreadCrumbItems } from './components/BreadCrumb/BreadCrumb'
import getEndpointUrl, { getCollectionToken, getEnv } from './utils/url-resolver'
import NewMtrModelForm from './routes/MtrModel/MtrModelForm/NewModelForm/NewMtrModelForm'
import EditMtrModelForm from './routes/MtrModel/MtrModelForm/EditModelForm/EditMtrModelForm'
import { BranchesInfo, CompanyInfo, CompanyMinimalInfo } from './models/Company/Company'
import isRouteAuthorized from './utils/AccessPermissionsCheck'
import SettingsRoute from './routes/Settings/SettingsRoute'
import Settings from './routes/Settings/Settings/Settings'
import NewPasswordRoute from './routes/NewPassword/NewPasswordRoute'
import NewPassword from './routes/NewPassword/NewPassword'
import NoPartnersPage from './routes/Gathering/NoPartnersPage/NoPartnersPage'
import ClientRoute from './routes/Clients/ClientRoute'
import ClientList from './routes/Clients/ClientList/ClientList'
import NewClientForm from './routes/Clients/ClientForm/NewClientForm/NewClientForm'
import EditClientForm from './routes/Clients/ClientForm/EditClientForm/EditClientForm'
import PartnersList from './routes/Partners/PartnersList/PartnersList'
import NewPartnerForm from './routes/Partners/PartnersForm/NewPartnerForm/NewPartnerForm'
import EditPartnerForm from './routes/Partners/PartnersForm/EditPartnerForm/EditPartnerForm'
import SystemUpdates from './routes/SystemUpdates/SystemUpdates'
import handleReactGA from './utils/handleReactAnalytics'

const isAPIRequest = (url: string): boolean => {
  return getPathname(url as string) !== '/oauth/token' && !url.includes('viacep') && !url.includes('mtr.meioambiente')
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401 && error.response.data.error === 'invalid_token') {
      signOut()
    }
  }
)

axios.interceptors.request.use((config) => {
  if (config.headers === undefined) {
    config.headers = {}
  }
  const tokenCompany = getTokenCompany()
  const tokenCollection = getCollectionToken()

  if (isAPIRequest(config.url as string) && config.url?.includes(getEndpointUrl('company'))) {
    if (!tokenCompany) {
      signOut()
    }
    config.headers.Authorization = `Bearer ${tokenCompany}`
  }
  if (isAPIRequest(config.url as string) && config.url?.includes(getEndpointUrl('collection'))) {
    if (!tokenCollection) {
      signOut()
    }
    config.headers.Authorization = `Bearer ${tokenCollection}`
  }
  if (config.url?.includes('mtr.meioambiente') && !config.url?.includes('gettoken')) {
    config.headers['Access-Control-Allow-Headers'] = 'Content-Type'
    config.headers['Access-Control-Allow-Origin'] = '*'
    config.headers['Content-Type'] = 'application/json'
    config.headers.Authorization = window.localStorage.getItem('tokenFeam') as string
    config.headers.chave_feam = window.localStorage.getItem('chaveFeam') as string
  }
  return config
})

const App: React.FC = () => {
  const classes = useStyles()
  const navigate = useNavigate()
  const location = useLocation()
  const userBranches = getUserBranches()
  const userCompanies = getUserCompanies()
  const [defaultBranch, setDefaultBranch] = React.useState<BranchesInfo>(
    userBranches && userBranches.length > 0 ? userBranches[0] : ({} as BranchesInfo)
  )
  const [defaultCompany, setDefaultCompany] = React.useState<CompanyMinimalInfo | undefined>(undefined)

  const [title, setTitle] = React.useState<string>('Home')
  const [isShowAlert, setIsShowAlert] = React.useState<boolean>(false)
  const [alertType, setAlertType] = React.useState<AlertColor>('success')
  const [alertText, setAlertText] = React.useState<string>('')
  const [customHeaderContent, setCustomHeaderContent] = React.useState<JSX.Element | null>(null)
  const [isShowLoading, setIsShowLoading] = React.useState<boolean>(false)
  const [isSidebarOpened, setIsSidebarOpened] = React.useState<boolean>(true)
  const [itemsBreadCrumb, setItemsBreadCrumb] = React.useState<BreadCrumbItems[]>([
    { label: 'Home', path: '/main/home' },
  ])

  const showAlert = React.useCallback((type: AlertColor, text: string): void => {
    setAlertType(type)
    setAlertText(text)
    setIsShowAlert(true)
  }, [])

  const changeStylesIfIOS = (): void => {
    const devicesList = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod']
    if (devicesList.includes(navigator.platform)) {
      document.querySelector('body')?.classList.add('ios-styles')
    }
  }

  React.useEffect(() => {
    changeStylesIfIOS()
  }, [])

  React.useEffect(() => {
    if (!isRouteAuthorized(location.pathname)) {
      navigate('/main/access-denied')
    }
  }, [location.pathname])

  const homeRoute = <Route path="home" key="home_element" element={<Home />} />
  const documentationRoute = (
    <Route path="documentation" key="documentation_element" element={<HomeRoute />}>
      <Route path="" element={<Navigate to="list" />} />
      <Route path="list" element={<Documentation />} />
    </Route>
  )
  const gatheringRoute = (
    <Route path="gathering" key="gathering_element" element={<GatheringRoute />}>
      <Route path="" element={<Navigate to="list" />} />
      <Route path="list" element={<GatheringList />} />
      <Route path="form" element={<GatheringForm isPageInMaintenance={false} />} />
    </Route>
  )
  const mtrModelRoute = (
    <Route path="mtrmodel" key="mtrmodel_element" element={<MtrModelRoute />}>
      <Route path="" element={<Navigate to="list" />} />
      <Route path="list" element={<MtrModelList />} />
      <Route path="form" element={<NewMtrModelForm />} />
      <Route path="form/:id" element={<EditMtrModelForm />} />
    </Route>
  )
  const vehicleRoute = (
    <Route path="vehicle" key="vehicle_element" element={<VehicleRoute />}>
      <Route path="" element={<Navigate to="list" />} />
      <Route path="list" element={<VehicleList />} />
      <Route path="form" element={<VehicleForm />} />
      <Route path="form/:id" element={<VehicleForm />} />
    </Route>
  )
  const employeeRoute = (
    <Route path="employee" key="employee_element" element={<EmployeeRoute />}>
      <Route path="" element={<Navigate to="list" />} />
      <Route path="list" element={<EmployeeList />} />
      <Route path="form" element={<EmployeeForm />} />
      <Route path="form/:id" element={<EmployeeForm />} />
    </Route>
  )
  const helpRoute = (
    <Route path="help" key="help_element" element={<HelpRoute />}>
      <Route path="" element={<Navigate to="tutorial" />} />
      <Route path="tutorial" element={<HelpPage />} />
    </Route>
  )
  const reportRoute = (
    <Route path="report" key="report_element" element={<ReportRoute />}>
      <Route path="" element={<Navigate to="list" />} />
      <Route path="form" element={<ReportForm />} />
      <Route path="form/:id" element={<ReportForm />} />
      <Route path="list" element={<ReportList />} />
      <Route path=":id" element={<ReportViewer />} />
    </Route>
  )
  const clientsRoute = (
    <Route path="clients" key="clients_element" element={<ClientRoute />}>
      <Route path="" element={<Navigate to="list" />} />
      <Route path="list" element={<ClientList />} />
      <Route path="form" element={<NewClientForm />} />
      <Route path="form/:identifier" element={<EditClientForm />} />
    </Route>
  )
  const partnersRoute = (
    <Route path="partners" key="partners_element" element={<ClientRoute />}>
      <Route path="" element={<Navigate to="list" />} />
      <Route path="list" element={<PartnersList />} />
      <Route path="form" element={<NewPartnerForm />} />
      <Route path="form/:identifier" element={<EditPartnerForm />} />
    </Route>
  )
  const contractRoute = (
    <Route path="contract" key="contract_element" element={<ContractRoute />}>
      <Route path="" element={<Navigate to="list" />} />
      <Route path="list" element={<ContractList />} />
      <Route path="form" element={<ContractForm />} />
      <Route path="form/:id" element={<ContractForm />} />
    </Route>
  )
  const userRoute = (
    <Route path="user" key="user_element" element={<UserRoute />}>
      <Route path="" element={<Navigate to="list" />} />
      <Route path="list" element={<UserList />} />
      <Route path="form" element={<UserForm />} />
      <Route path="form/:id" element={<UserForm />} />
    </Route>
  )
  const settingsRoute = (
    <Route path="settings" key="settings_element" element={<SettingsRoute />}>
      <Route path="" element={<Navigate to="list" />} />
      <Route path="panel" element={<Settings />} />
    </Route>
  )
  const newPasswordRoute = (
    <Route path="change-password" key="change-password_element" element={<NewPasswordRoute />}>
      <Route path="" element={<Navigate to="form" />} />
      <Route path="form" element={<NewPassword />} />
    </Route>
  )

  const accessDeniedRoute = <Route path="access_denied" element={<NoPartnersPage />} key="access_denied_partners" />

  const routes: ReactElement[] = [
    homeRoute,
    gatheringRoute,
    mtrModelRoute,
    vehicleRoute,
    employeeRoute,
    helpRoute,
    reportRoute,
    documentationRoute,
    userRoute,
    contractRoute,
    clientsRoute,
    partnersRoute,
    settingsRoute,
    newPasswordRoute,
    accessDeniedRoute,
    <Route path="system-updates/:identifier" element={<SystemUpdates />} key="system-updates" />,
    <Route element={NotFound} key="notFound" />,
    <Route path="/access-denied" element={<AccessDenied />} key="access-denied" />,
    <Route path="/main" element={<Navigate to="home" />} key="home_page" />,
  ]

  const appContextProvider = React.useMemo(
    () => ({
      userCompanies,
      userBranches,
      defaultBranch,
      defaultCompany,
      setDefaultCompany,
      title,
      setTitle,
      customHeaderContent,
      setCustomHeaderContent,
      showAlert,
      setIsShowLoading,
      isSidebarOpened,
      setIsSidebarOpened,
      itemsBreadCrumb,
      setItemsBreadCrumb,
    }),
    [
      userCompanies,
      userBranches,
      defaultBranch,
      defaultCompany,
      setDefaultCompany,
      title,
      setTitle,
      customHeaderContent,
      setCustomHeaderContent,
      setIsShowLoading,
      showAlert,
      isSidebarOpened,
      setIsSidebarOpened,
      itemsBreadCrumb,
      setItemsBreadCrumb,
    ]
  )

  React.useEffect(() => {
    const env = getEnv(window.location.host)
    handleReactGA(env)
  }, [])

  return (
    <AppContext.Provider value={appContextProvider}>
      <Loading isShowLoading={isShowLoading} />
      <Alerts isShowAlert={isShowAlert} setIsShowAlert={setIsShowAlert} alertText={alertText} alertType={alertType} />
      <div id="container" className={classes.body} key={classes.body}>
        <SidebarV2 />
        <div className={classes.containerMain} key={classes.containerMain}>
          <Header />
          <main key="main">
            <Routes>{routes}</Routes>
          </main>
        </div>
      </div>
    </AppContext.Provider>
  )
}

export default App

import { Autocomplete, AutocompleteChangeReason, AutocompleteRenderInputParams, TextField } from '@mui/material'
import FormControl from '@mui/material/FormControl'
import FormHelperText from '@mui/material/FormHelperText'
import React from 'react'
import Icons from '../../../assets/icons/svgIcons'

export interface Select2Options {
  value: string | number
  label: string | number
  disabled?: boolean
}

export type TextFieldStyle = {
  variant: 'filled' | 'outlined' | 'standard'
  focused: boolean
}

export type InputSelect2Props = {
  id: string
  label: string
  onChange: (event: React.SyntheticEvent, value: Select2Options | null, reason: AutocompleteChangeReason) => void
  options: Select2Options[]
  errorText?: string
  clearable?: boolean
  disabled?: boolean
  defaultValue?: number | string
  value: number | string | undefined
  filterOptions?: (opt: Select2Options[], inputValue: string) => Select2Options[]
  sx?: React.CSSProperties
  popupIcon?: React.ReactNode
  textFieldStyle?: TextFieldStyle
}

const InputSelect2: React.ForwardRefRenderFunction<React.Ref<HTMLInputElement>, InputSelect2Props> = (
  { id, label, onChange, options, errorText, clearable = false, disabled = false, ...rest },
  ref
) => {
  const hasError = !!errorText
  const disabledValues = options.filter((option) => option.disabled).map((option) => option.value)
  const checkValue = (option: Select2Options, value: Select2Options | null): boolean => {
    return option.value === value?.value
  }
  const disableOption = (option: Select2Options): boolean => {
    return disabledValues.includes(option.value)
  }
  const initialValue = options.find((o) => o.value === rest.value)
  const defaultValue = options.find((o) => o.value === rest.defaultValue)
  let autoCompleteValue = null
  let autoCompleteDefaultValue = null

  if (initialValue !== undefined && defaultValue === undefined) {
    autoCompleteValue = initialValue
  } else if (defaultValue !== undefined) {
    autoCompleteValue = undefined
  }

  if (defaultValue !== undefined) {
    autoCompleteDefaultValue = defaultValue
  }

  const searchOptions = (opt: Select2Options[], { inputValue }: { inputValue: string }): Select2Options[] => {
    return opt.filter((option) => {
      const optLabel = option.label as string
      return optLabel.toLowerCase().includes(inputValue.toLowerCase())
    })
  }

  return (
    <FormControl fullWidth variant="outlined" error={hasError} sx={{ '&.MuiFormControl-root': { display: 'flex' } }}>
      <Autocomplete
        fullWidth
        id={id}
        onChange={onChange}
        sx={{
          ...rest.sx,
          '& .MuiAutocomplete-popupIndicator': { transform: 'none' },
        }}
        popupIcon={<Icons.Search />}
        renderInput={(params: AutocompleteRenderInputParams): React.ReactNode => (
          <TextField
            inputRef={ref}
            label={label}
            error={hasError}
            variant={rest.textFieldStyle?.variant || 'outlined'}
            sx={{ backgroundColor: 'white', borderRadius: '4px' }}
            focused={rest.textFieldStyle?.focused || false}
            {...params}
          />
        )}
        data-cy={`select2-input-${id}`}
        renderOption={(props, option) => (
          <li {...props} key={option.value} data-cy={`select-option-${(option.label as string).replace(/\s/g, '')}`}>
            {option.label}
          </li>
        )}
        options={options}
        isOptionEqualToValue={checkValue}
        getOptionDisabled={disableOption}
        filterOptions={searchOptions}
        noOptionsText="Nenhum resultado encontrado"
        value={autoCompleteValue}
        disableClearable={!clearable}
        multiple={false}
        disabled={disabled}
        defaultValue={autoCompleteDefaultValue}
      />
      {errorText && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  )
}

export default React.forwardRef(InputSelect2)

/* eslint-disable react/no-array-index-key */
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { Grid } from '@mui/material'
import React from 'react'
import useStyles, { smoothWhite, white } from './BreadCrumbStyles'

export interface BreadCrumbItems {
  label: string
  path: string
}

export const defaultBreadCrumbItems: BreadCrumbItems[] = [{ label: 'Início', path: '/main/home' }]

interface BreadCrumbProps {
  items: BreadCrumbItems[]
}

const BreadCrumb: React.FC<BreadCrumbProps> = ({ items }: BreadCrumbProps) => {
  const classes = useStyles()

  return (
    <Grid className={classes.containerGrid}>
      <Typography color={smoothWhite} className={classes.typography}>
        Você está aqui:&nbsp;
      </Typography>
      <Breadcrumbs aria-label="breadcrumb" separator={<span className={classes.separator}>›</span>}>
        {items.map((item, index) => {
          if (index === items.length - 1) {
            return (
              <Typography key={index} color={smoothWhite}>
                {item.label}
              </Typography>
            )
          }
          return (
            <Link key={index} href={item.path} color={white}>
              {item.label}
            </Link>
          )
        })}
      </Breadcrumbs>
    </Grid>
  )
}

export default BreadCrumb

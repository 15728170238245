import { Button } from '@mui/material'
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'
import * as yup from 'yup'
import useYupValidationResolver from '../../../utils/yup-validator-resolver'

import AppContext, { AppContextType } from '../../../AppContext'
import InputText from '../../../components/Form/Input/Input'
import InputSelect from '../../../components/Form/Select/Select'
import InputSelect2, { Select2Options } from '../../../components/Form/Select/Select2'
import { getAllCustomers } from '../../../services/Companies/CompaniesService'
import Report, { ReportTypeEnum } from '../../../models/Report/report'
import { createReport, getReportByIdentifier, updateReport } from '../../../services/Report/ReportService'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'
import { CompanyMinimalInfo } from '../../../models/Company/Company'
import getInitialSelectedCompany, { getCompanyByIdentifier } from '../../../utils/getInitialSelectedCompany'
import { handleReactGAEvent } from '../../../utils/handleReactAnalytics'

const validationSchema = yup.object({
  customerIdentifier: yup.string().required('campo obrigatório'),
  name: yup.string().required('campo obrigatório'),
  type: yup.string().required('campo obrigatório'),
  url: yup.string().required('campo obrigatório'),
})

const ReportForm: React.FC = () => {
  const {
    setTitle,
    setCustomHeaderContent,
    setIsShowLoading,
    showAlert,
    setItemsBreadCrumb,
    defaultCompany,
    setDefaultCompany,
  } = React.useContext(AppContext as React.Context<AppContextType>)
  const resolver = useYupValidationResolver(validationSchema)
  const { control, formState, handleSubmit, setValue, reset } = useForm<Report>({ resolver })
  const navigate = useNavigate()
  const [customers, setCustomers] = React.useState<CompanyMinimalInfo[]>([])
  const [customersOptions, setCustomersOptions] = React.useState<{ value: string; label: string }[]>([])
  const { id } = useParams<{ id: string | undefined }>()

  const onSubmit: SubmitHandler<Report> = (data: Report): void => {
    setIsShowLoading(true)
    if (!id) {
      createReport(data.customerIdentifier, data)
        .then(() => {
          handleReactGAEvent({
            category: 'Relatório',
            action: 'Salvar',
            label: `Salvou um relatorio`,
          })
          showAlert('success', 'Relatório salvo com sucesso.')
          setIsShowLoading(false)
          navigate('/main/report/list')
        })
        .catch(() => {
          handleReactGAEvent({
            category: 'Relatório',
            action: 'Salvar',
            label: `Erro ao salvar relatorio`,
          })
          showAlert('error', 'Erro ao salvar relatório. Tente novamente mais tarde.')
          setIsShowLoading(false)
        })
    } else {
      updateReport(id, data)
        .then(() => {
          handleReactGAEvent({
            category: 'Relatório',
            action: 'Editar',
            label: `Editou um relatorio`,
          })
          showAlert('success', 'Relatório editado com sucesso.')
          setIsShowLoading(false)
          navigate('/main/report/list')
        })
        .catch(() => {
          handleReactGAEvent({
            category: 'Relatório',
            action: 'Editar',
            label: `Erro ao editar relatorio`,
          })
          showAlert('error', 'Erro ao editar relatório. Tente novamente mais tarde.')
          setIsShowLoading(false)
        })
    }
  }
  const setDefaultCompanyInfo = (customerIdentifier: string): void => {
    const customerInfo = getCompanyByIdentifier(customers, customerIdentifier)
    if (customerInfo) setDefaultCompany(customerInfo)
  }

  const handleCustomerChange = (event: React.SyntheticEvent, value: Select2Options | null): void => {
    setValue('customerIdentifier', value?.value as string)
    setDefaultCompanyInfo(value?.value as string)
  }

  const setInitialSelectedCustomer = (customersInfo: CompanyMinimalInfo[]): void => {
    const initialSelectedCompany = getInitialSelectedCompany(customersInfo, defaultCompany)
    setValue('customerIdentifier', initialSelectedCompany.identifier)
  }

  const handleInit = (): void => {
    getAllCustomers().then((customersInfo) => {
      setCustomers(customersInfo)
      setCustomersOptions(
        customersInfo.map((c) => {
          return { value: c.identifier, label: c.name }
        })
      )
      setInitialSelectedCustomer(customersInfo)
    })
    if (id) {
      getReportByIdentifier(id).then((report) => {
        reset({
          customerIdentifier: report.customerIdentifier,
          name: report.name,
          type: report.type,
          url: report.url,
        })
      })
    }

    setTitle(id ? 'Editar Relatório' : 'Novo Relatório')
    setCustomHeaderContent(<div />)
  }

  React.useEffect(() => {
    handleInit()
    setItemsBreadCrumb([
      ...defaultBreadCrumbItems,
      { label: 'Listar relatórios', path: '/main/report/list' },
      { label: id ? 'Editar relatório' : 'Novo relatório', path: id ? `/main/report/form/${id}` : '/main/report/form' },
    ])
  }, [])

  return (
    <Card className="card card--form card-forms-styles" variant="outlined">
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="none">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Controller
              name="customerIdentifier"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputSelect2
                  id="customerIdentifier"
                  label="Cliente"
                  errorText={formState.errors?.customerIdentifier?.message}
                  options={customersOptions}
                  value={field.value}
                  onChange={handleCustomerChange}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText id="name" label="Nome" errorText={formState.errors?.name?.message} {...field} />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="type"
              control={control}
              defaultValue={ReportTypeEnum.FEAM}
              render={({ field }) => (
                <InputSelect
                  id="type"
                  label="Tipo Relatório"
                  errorText={formState.errors?.type?.message}
                  options={[{ value: 'Feam', label: 'Feam' }]}
                  {...field}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="url"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText id="url" label="Url" errorText={formState.errors?.url?.message} {...field} />
              )}
            />
          </Grid>
        </Grid>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: '24px',
          }}
        >
          <Button variant="contained" color="primary" type="submit">
            Salvar
          </Button>
        </Grid>
      </form>
    </Card>
  )
}

export default ReportForm

import { getUserFeatures } from '../services/Auth/TokenService'
import allPagesInfo, { IPageInfo } from '../models/Pages/AllPagesInfo'

export const isUserAllowedInPage = (location: string): IPageInfo[] => {
  const features = getUserFeatures()
  return allPagesInfo.filter((page) => features?.includes(page.feature)).filter((page) => location.match(page.link))
}
export const getUserAllowedPagesInfo = (): IPageInfo[] => {
  const features = getUserFeatures()
  return allPagesInfo.filter((page) => features?.includes(page.feature))
}
const isRouteAuthorized = (location: string): boolean => {
  return !!isUserAllowedInPage(location).length
}

export const featureAccessCheck = (feature: string): boolean => {
  const features = getUserFeatures()
  return features?.includes(feature) || false
}

export const iconAccessCheck = (feature: string): boolean => {
  if (featureAccessCheck(feature)) {
    return true
  }
  return false
}

export default isRouteAuthorized

import React from 'react'
import SearchIcon from '@mui/icons-material/Search'
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material'
import { useNavigate, useLocation } from 'react-router-dom'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import Logo from '../../assets/images/logo_new.png'
import LogoShort from '../../assets/images/logo.png'
import { useStyles } from './SidebarStyles'
import { signOut } from '../../services/Auth/TokenService'
import AppContext, { AppContextType } from '../../AppContext'
import { IPageInfo } from '../../models/Pages/AllPagesInfo'
import { getUserAllowedPagesInfo } from '../../utils/AccessPermissionsCheck'
import InputText from '../Form/Input/Input'
import { ApplicationColors } from '../../utils/applicationColors'
import IconPicture from '../../models/Icon/icon'

interface MenuOptions {
  picture: IconPicture
  title: string
  link: string
  description: string
}

const SidebarV2: React.FC = () => {
  const navigate = useNavigate()
  const classes = useStyles()
  const { isSidebarOpened, setIsSidebarOpened } = React.useContext(AppContext as React.Context<AppContextType>)
  const asideElement = React.useRef<any>()
  const [menuOptions, setMenuOptions] = React.useState<IPageInfo[]>([])
  const [filteredMenuOptions, setFilteredMenuOptions] = React.useState<IPageInfo[]>([])
  const [menuPage, setMenuPage] = React.useState<string>('')
  const location = useLocation()

  const handleNavigate = (link: string): void => {
    navigate(link)
  }

  const handleMenuSearch = (event: React.FormEvent<HTMLInputElement>): void => {
    const search = (event.target as HTMLInputElement).value.toLocaleLowerCase()
    setFilteredMenuOptions(menuOptions.filter((option) => option.title.toLocaleLowerCase().includes(search)))
  }

  const toggleSidebar = (): void => {
    if (asideElement.current.style.width === '70px') {
      asideElement.current.style.width = '300px'
      asideElement.current.parentElement.style.flexBasis = '300px'
      setIsSidebarOpened(true)
    } else {
      asideElement.current.style.width = '70px'
      asideElement.current.parentElement.style.flexBasis = '70px'
      setIsSidebarOpened(false)
    }
  }

  const setCurrentMenuPage = (fullMenu: IPageInfo[]): void => {
    const mainPagePath = location.pathname.split('/').splice(1, 2).join('/')
    fullMenu.forEach((m) => {
      if (m.link.includes(mainPagePath)) setMenuPage(m.title)
    })
  }

  React.useEffect(() => {
    const menu = getUserAllowedPagesInfo()
    const fullMenu = menu.filter((page) => page.showSideBar)
    const filteredMenu = menu.filter((page) => page.feature === 'config-read' || page.feature === 'help-read')
    setMenuOptions(fullMenu)
    setFilteredMenuOptions(filteredMenu)
    setCurrentMenuPage(fullMenu)
  }, [])

  const handleLogout = (): void => {
    signOut()
  }

  return (
    <aside style={{ transition: 'all 0.3s', zIndex: 999 }}>
      <div ref={asideElement} className={classes.aside}>
        <div className={classes.asideHeaderButtons}>
          <button
            type="button"
            style={{ background: 'transparent', border: '0px' }}
            className={classes.asideHeader}
            onClick={() => handleNavigate('/main/home')}
          >
            <img
              src={isSidebarOpened ? Logo : LogoShort}
              alt="logo recicla club"
              className={!isSidebarOpened ? 'short' : ''}
            />
          </button>
          <button type="button" className={classes.toggleButton} onClick={toggleSidebar}>
            {isSidebarOpened ? <ArrowBackIosNew fontSize="small" /> : <ArrowForwardIos fontSize="small" />}
          </button>
        </div>
        {isSidebarOpened && (
          <div className={classes.searchBox}>
            <SearchIcon />
            <InputText id="search" placeholder="Procurar conversa" onInput={handleMenuSearch} />
          </div>
        )}
        <nav className={classes.navMenu}>
          <div
            style={{
              height: 'calc(100vh - 136px)',
              overflow: 'auto',
              overflowX: 'hidden',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Divider />
            <List sx={{ height: 'calc(100% - 60px)', width: '100%' }}>
              {menuOptions
                .filter((i) => i.feature !== 'config-read' && i.feature !== 'help-read')
                .map((h) => (
                  <ListItem key={h.title} disablePadding>
                    <ListItemButton
                      data-cy={h.feature}
                      onClick={() => {
                        handleNavigate(h.link)
                        setMenuPage(h.title)
                      }}
                      sx={{ borderRight: menuPage === h.title ? '4px solid #33B652' : 'none', width: '100%' }}
                    >
                      {h.picture && (
                        <ListItemIcon>
                          <h.picture
                            height={24}
                            width={24}
                            fill={menuPage === h.title ? ApplicationColors.primaryColor : 'black'}
                          />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primary={h.title}
                        style={{
                          whiteSpace: 'nowrap',
                          color: h.title === menuPage ? ApplicationColors.primaryColor : 'black',
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              <Divider />
              {filteredMenuOptions
                .filter((i) => i.feature === 'config-read' || i.feature === 'help-read')
                .map((h) => (
                  <ListItem key={h.title} disablePadding>
                    <ListItemButton
                      onClick={() => {
                        handleNavigate(h.link)
                        setMenuPage(h.title)
                      }}
                      sx={{ borderRight: menuPage === h.title ? '4px solid #33B652' : 'none', width: '100%' }}
                    >
                      {h.picture && (
                        <ListItemIcon>
                          <h.picture
                            height={24}
                            width={24}
                            fill={menuPage === h.title ? ApplicationColors.primaryColor : 'black'}
                          />
                        </ListItemIcon>
                      )}
                      <ListItemText
                        primary={h.title}
                        style={{
                          whiteSpace: 'nowrap',
                          color: h.title === menuPage ? ApplicationColors.primaryColor : 'black',
                        }}
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              <Divider />
              <ListItem disablePadding>
                <ListItemButton onClick={handleLogout} sx={{ borderRight: 'none', width: '100%' }}>
                  <ListItemIcon>
                    <img
                      src={`${process.env.PUBLIC_URL}/menu/logout.svg`}
                      style={{ height: '24px', width: '24px', marginRight: '32px' }}
                      alt="logout"
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary="Sair do sistema"
                    style={{
                      whiteSpace: 'nowrap',
                      color: 'black',
                    }}
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </div>
        </nav>
      </div>
    </aside>
  )
}

export default SidebarV2

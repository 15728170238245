import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Button, Drawer, FormLabel, Grid, IconButton, InputAdornment, InputLabel } from '@mui/material'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined'
import * as yup from 'yup'
import useYupValidationResolver from '../../../../../../utils/yup-validator-resolver'
import Select from '../../../../../Form/Select/Select'
import InputText from '../../../../../Form/Input/Input'
import { FinancialTransactionInfo, FinancialTransactionRequest } from '../../../../../../models/Client/Client'
import useStyles from './TransactionsFormStyle'

const schema = yup.object().shape({
  type: yup.string(),
  description: yup.string(),
  quantity: yup.number(),
  unitValue: yup.string(),
  unitMeasurement: yup.string(),
})

interface TransactionsFormProps {
  title: string
  open: boolean
  onClose: () => void
  onSubmit: (data: FinancialTransactionRequest) => void
  executed: boolean
  transactionToEdit?: FinancialTransactionInfo
}

const TransactionsForm: React.FC<TransactionsFormProps> = ({
  title,
  onSubmit,
  open,
  onClose,
  executed,
  transactionToEdit,
}) => {
  const resolver = useYupValidationResolver(schema)
  const { handleSubmit, setValue, control, formState, getValues, reset } = useForm<FinancialTransactionRequest>({
    resolver,
  })
  const classes = useStyles()

  React.useEffect(() => {
    if (open && transactionToEdit) {
      reset(transactionToEdit)
    } else {
      reset({
        type: 'FIXED',
        description: '',
        quantity: 0,
        unitValue: '',
        unitMeasurement: '',
      })
    }
  }, [transactionToEdit, open])

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <form
        className={classes.form}
        onSubmit={(event) => {
          event.stopPropagation()
          handleSubmit(onSubmit)(event)
        }}
      >
        <Grid container className={classes.header}>
          <Grid item xs={10} className={classes.headerTitle}>
            <h1>{title}</h1>
          </Grid>
          <Grid item xs={2} className={classes.closeButton}>
            <IconButton onClick={onClose}>
              <CloseOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid>
        <FormLabel component="label" className={classes.labelStyle}>
          Tipo
          <Controller
            name="type"
            control={control}
            defaultValue="FIXED"
            render={({ field }) => (
              <Select
                label=""
                id="type"
                name="type"
                errorText={formState.errors?.type?.message}
                options={[
                  { value: 'FIXED', label: 'Fixo' },
                  { value: 'VARIABLE', label: 'Variável' },
                ]}
                value={getValues('type')}
                onChange={({ target }) => setValue('type', target.value)}
              />
            )}
          />
        </FormLabel>
        <FormLabel component="label" className={classes.labelStyle}>
          Descrição
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <InputText
                label=""
                id="description"
                name="description"
                errorText={formState.errors?.description?.message}
                value={getValues('description')}
                onChange={({ target }) => setValue('description', target.value)}
              />
            )}
          />
        </FormLabel>
        <FormLabel component="label" className={classes.labelStyle}>
          Quantidade
          <Controller
            name="quantity"
            control={control}
            render={({ field }) => (
              <InputText
                label=""
                id="quantity"
                name="quantity"
                errorText={formState.errors?.quantity?.message}
                type="number"
                value={getValues('quantity')}
                onChange={({ target }) => setValue('quantity', Number(target.value))}
              />
            )}
          />
        </FormLabel>
        <FormLabel component="label" className={classes.labelStyle}>
          {title.includes('Comissão') ? 'Condição' : 'Unidade de Medida'}
          <Controller
            name="unitMeasurement"
            control={control}
            render={({ field }) => (
              <InputText
                label=""
                id="unitMeasurement"
                name="unitMeasurement"
                value={getValues('unitMeasurement')}
                errorText={formState.errors?.unitMeasurement?.message}
                onChange={({ target }) => setValue('unitMeasurement', target.value)}
              />
            )}
          />
        </FormLabel>
        <FormLabel component="label" className={classes.labelStyle}>
          Valor Unitário
          <Controller
            name="unitValue"
            control={control}
            defaultValue={getValues('unitValue')}
            render={({ field }) => (
              <InputText
                label=""
                id="unitValue"
                errorText={formState.errors?.unitValue?.message}
                type="number"
                InputProps={{ startAdornment: <InputAdornment position="start">R$</InputAdornment> }}
                {...field}
              />
            )}
          />
        </FormLabel>
        {executed && (
          <FormLabel component="label" className={classes.labelStyle}>
            Data de Execução
            <Controller
              name="executedDate"
              control={control}
              render={({ field }) => (
                <InputText
                  label=""
                  id="executionDate"
                  name="executionDate"
                  errorText={formState.errors?.executedDate?.message}
                  type="date"
                  value={getValues('executedDate')}
                  onChange={({ target }) => setValue('executedDate', target.value)}
                />
              )}
            />
          </FormLabel>
        )}
        <Grid className={classes.buttonContainer}>
          <Button className={classes.clearButton} type="button" onClick={onClose}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" type="submit" className={classes.filterButton}>
            Salvar
          </Button>
        </Grid>
      </form>
    </Drawer>
  )
}

export default TransactionsForm

import {
  FeamAcondicionamento,
  FeamClasses,
  FeamEstadoFisico,
  FeamResiduos,
  FeamTecnologias,
  FeamUnidades,
} from '../../models/Feam/Feam'

type FeamData = {
  classes: { value: number; label: string }[]
  estadosFisicos: { value: number; label: string }[]
  unidades: { value: number; label: string }[]
  tecnologias: { value: number; label: string }[]
  acondicionamento: { value: string; label: string }[]
  residuos: { value: string; label: string }[]
}

const getAllFeamData = (): FeamData => ({
  classes: FeamClasses,
  estadosFisicos: FeamEstadoFisico,
  unidades: FeamUnidades,
  tecnologias: FeamTecnologias,
  acondicionamento: FeamAcondicionamento,
  residuos: FeamResiduos,
})

export default getAllFeamData

import axios from 'axios'

import Employee, { DriverResponse, EmployeeMinimalResponse } from '../../models/Employee/employee'
import getEndpointUrl from '../../utils/url-resolver'

const baseUrl = `${getEndpointUrl('company')}/v1/drivers`

const getDriversByPartnerIdentifier = async (partnerIdentifier: string): Promise<DriverResponse[]> => {
  return axios.get(`${baseUrl}/partner/${partnerIdentifier}`).then((response) => response.data)
}

export { getDriversByPartnerIdentifier }

import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import { PartnerFormType } from '../../../../models/Company/Company'
import AppContext, { AppContextType } from '../../../../AppContext'
import { defaultBreadCrumbItems } from '../../../../components/BreadCrumb/BreadCrumb'

import useYupValidationResolver from '../../../../utils/yup-validator-resolver'
import validationSchema from '../utils/companyValidationSchema'
import setRequestData, { setContactData } from '../utils/setRequestData'
import PartnersForm from '../PartnersForm'
import {
  PartnerRequest,
  PartnerResponse,
  PartnersDocuments,
  City,
  PartnerDocumentInfos,
  PartnerContact,
  PartnerBankData,
} from '../../../../models/Partner/Partner'
import { getPartnerByIdentifier, updatePartner } from '../../../../services/Partner/PartnerService'
import {
  savePartnerDocumentFiles,
  savePartnerDocumentInfos,
  savePartnerFacade,
} from '../../../../services/Partner/PartnerDocumentsService'
import { extractDocumentsList, removeDocumentFileField } from '../utils/setDocumentsList'
import { createPartnerContact, updatePartnerContact } from '../../../../services/Partner/PartnerContactsService'
import { documentsOptionsList } from '../../../../models/Company/FormOptions/DocumentsOptions'

interface IDocumentsInfo {
  file?: File
  documents?: PartnersDocuments
}

const EditPartnerForm: React.FC = () => {
  const resolver = useYupValidationResolver(validationSchema)
  const form = useForm<PartnerFormType>({ resolver })
  const { reset, setValue, getValues } = form
  const { setItemsBreadCrumb, setTitle, setIsShowLoading, showAlert } = React.useContext(
    AppContext as React.Context<AppContextType>
  )
  const { identifier } = useParams<{ identifier: string }>()

  const navigate = useNavigate()
  const contactTypes = {
    Financeiro: 'financeContact',
    Operacional: 'operationalContact',
    Comercial: 'commercialContact',
    Diretoria: 'managementContact',
  }

  const setStatesList = (citiesList: City[]): string[] => {
    const states = citiesList.map((city) => city.state)
    return states.filter((state, index) => states.indexOf(state) === index)
  }

  const setCitiesList = (citiesList: City[]): string[] => {
    return citiesList.map((city) => `${city.name} - ${city.state} - ${city.idCode}`)
  }

  const setCompanysInformation = (partnerInfo: PartnerResponse): void => {
    const { primaryData, partnerType, bankData, technicalData, documents } = partnerInfo
    const { name, cnpj, companyName, icmsTaxpayer, taxRegime, address, companyFacade, mtrSystemAccess } = primaryData
    const { zipcode, state, city, district, street, number, complement } = address

    const { bankCode = '0', bankBranch = '0', accountNumber = '0', maxPaymentTerm = '0' } = bankData || {}

    reset({
      name,
      cnpj,
      corporateName: companyName,
      serviceType: partnerType,
      icms: icmsTaxpayer,
      taxRegime,
      cep: zipcode,
      state,
      city,
      district,
      street,
      number: Number(number),
      complement,
      bankCode: Number(bankCode) || 0,
      bankBranch: Number(bankBranch) || 0,
      accountNumber: Number(accountNumber) || 0,
      maxPaymentTerm: Number(maxPaymentTerm) || 0,
      residues: technicalData.residues || [],
      operationStatus: technicalData.operationStatus,
      homologationStatus: technicalData.homologStatus,
      statesActive: setStatesList(technicalData.cities as City[]),
      citiesActive: setCitiesList(technicalData.cities as City[]),
      trucksQuantity: technicalData.truckQuantity,
      employeesQuantity: technicalData.employeeQuantity,
      companysFacadeLink: companyFacade,
      documentationLink: documents.documentationLink,
      documents,
      observations: [],
      branch: mtrSystemAccess.mtrSystemUnitCode,
    })
  }

  const setContactsValues = (partnerInfo: PartnerResponse): void => {
    partnerInfo.contacts.forEach((contact) => {
      const prefix = contactTypes[contact.type as keyof typeof contactTypes]
      if (prefix) {
        setValue(`${prefix}.name` as keyof PartnerFormType, contact.name)
        setValue(`${prefix}.surname` as keyof PartnerFormType, contact.surname)
        setValue(`${prefix}.email` as keyof PartnerFormType, contact.email)
        setValue(`${prefix}.telephone` as keyof PartnerFormType, contact.telephone)
        setValue(`${prefix}.identifier` as keyof PartnerFormType, contact.identifier)
      }
    })
  }

  const setCompanysInfo = React.useCallback((): void => {
    setIsShowLoading(true)

    getPartnerByIdentifier(identifier as string)
      .then((partnerInfo) => {
        setCompanysInformation(partnerInfo)
        setContactsValues(partnerInfo)
      })
      .catch((e) => {
        showAlert('error', 'Erro ao capturar informações da empresa.')
      })
      .finally(() => {
        setIsShowLoading(false)
      })
  }, [identifier, reset, showAlert])

  const requestUpdate = async (partnerRequest: PartnerRequest): Promise<IDocumentsInfo> => {
    try {
      await updatePartner(partnerRequest, identifier as string)
      showAlert('success', 'Parceiro atualizado com sucesso')
      return {
        file: form.getValues('companysFacade') || undefined,
        documents: form.getValues('documents') || undefined,
      }
    } catch {
      throw new Error('Erro ao atualizar parceiro')
    } finally {
      setIsShowLoading(false)
    }
  }

  const requestUpdateCompanysFacade = ({ file, documents }: IDocumentsInfo): PartnersDocuments => {
    if (file) {
      savePartnerFacade(identifier as string, file).catch(() => {
        throw new Error('Erro ao salvar fachada da empresa')
      })
    }
    return documents as PartnersDocuments
  }

  const requestUpdatePartnerDocumentsInfo = (documents: PartnersDocuments): PartnerDocumentInfos[] => {
    const documentList = extractDocumentsList(documents)
    const documentsObjWithoutFile = removeDocumentFileField(documentList)
    savePartnerDocumentInfos(identifier as string, documentsObjWithoutFile).catch(() => {
      throw new Error('Erro ao salvar informações dos documentos. Tente novamente ou contate o suporte.')
    })
    return documentList
  }

  const requestUpdateCompanyDocumentFile = async (documentsList: PartnerDocumentInfos[] | null): Promise<void> => {
    setIsShowLoading(true)

    if (!documentsList || documentsList.length === 0) {
      setIsShowLoading(false)
      return
    }

    const documentsToSave = documentsList
      .filter((document) => document.documentFile instanceof File)
      .map((document) => ({
        name: documentsOptionsList.find((doc) => doc.value === document.name)?.label as string,
        file: document.documentFile as File,
      }))
      .filter((doc) => doc.name) // Filtra documentos sem nome válido

    try {
      await savePartnerDocumentFiles(identifier as string, documentsToSave)
    } catch (error: unknown) {
      const message =
        error instanceof Error
          ? `Erro ao editar documento: ${error.message}`
          : 'Erro desconhecido ao tentar salvar documento'
      console.error(message)
    } finally {
      setIsShowLoading(false)
    }
  }

  const requestUpdatePartnerContacts = async (contacts: PartnerContact[]): Promise<void> => {
    setIsShowLoading(true)
    await Promise.all(
      contacts.map(async (contact) => {
        const { name } = contact
        try {
          if (contact.identifier) {
            await updatePartnerContact(identifier as string, contact.identifier!, contact)
          } else if (name && name !== '') {
            await createPartnerContact(identifier as string, contact)
          }
        } catch {
          throw new Error(`Erro ao editar contato ${contact.name} do parceiro. Contate o suporte.`)
        }
      })
    )
    setIsShowLoading(false)
  }

  const onSubmit = (data: PartnerFormType): void => {
    setIsShowLoading(true)
    const companyUpdateRequestInfo = setRequestData(data)
    const partnerContacts = setContactData(data)

    requestUpdate(companyUpdateRequestInfo)
      .then((documentsInfo) => requestUpdateCompanysFacade(documentsInfo))
      .then((documentsInfo) => requestUpdatePartnerDocumentsInfo(documentsInfo))
      .then((documentsInfo) => requestUpdateCompanyDocumentFile(documentsInfo))
      .then(() => requestUpdatePartnerContacts(partnerContacts))
      .then(() => navigate(`/main/partners/form/${identifier}`))
      .catch((error: Error) => showAlert('error', error.message))
      .finally(() => {
        setIsShowLoading(false)
        window.location.reload()
      })
  }

  React.useEffect(() => {
    setCompanysInfo()
    setTitle('Editar parceiro')
    setItemsBreadCrumb([
      ...defaultBreadCrumbItems,
      { label: 'Listar Parceiros', path: '/main/partners/list' },
      { label: 'Editar Parceiro', path: '/main/partners/form' },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return getValues('branch') !== undefined || getValues('branch') !== null ? (
    <PartnersForm form={form} onSubmit={onSubmit} id={identifier} />
  ) : (
    <div />
  )
}

export default EditPartnerForm

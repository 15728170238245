import React from 'react'
import { Button, Card, FormHelperText, FormLabel, Grid, InputLabel, TextField } from '@mui/material'
import * as yup from 'yup'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { createNewPassword } from '../../../services/NewPassword/NewPasswordService'
import { ChangePasswordForm, ChangePasswordParam } from '../../../models/User/user'
import InputText from '../../../components/Form/Input/Input'
import AppContext, { AppContextType } from '../../../AppContext'
import useYupValidationResolver from '../../../utils/yup-validator-resolver'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'
import useStyles from '../NewPasswordStyle'
import ShowPassword from '../../../components/ShowPassword/ShowPassword'

interface NewPasswordFormProps {
  validationSchema: yup.ObjectSchema<any, any, any, any>
  setValidationStatus?: React.Dispatch<
    React.SetStateAction<{
      length: boolean
      number: boolean
      uppercase: boolean
      lowercase: boolean
      specialChar: boolean
    }>
  >
}

const NewPasswordForm: React.FC<NewPasswordFormProps> = ({ validationSchema, setValidationStatus }) => {
  const [showOldPassword, setShowOldPassword] = React.useState(false)
  const [showNewPassword, setShowNewPassword] = React.useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false)

  const { showAlert, setItemsBreadCrumb, setTitle, setCustomHeaderContent, setIsShowLoading } = React.useContext(
    AppContext as React.Context<AppContextType>
  )
  const classes = useStyles()
  const navigate = useNavigate()
  const resolver = useYupValidationResolver(validationSchema)
  const { handleSubmit, formState, control, setValue, watch } = useForm<ChangePasswordForm>({ resolver })

  const handleShowOldPassword = (): void => {
    setShowOldPassword(!showOldPassword)
  }

  const handleShowNewPassword = (): void => {
    setShowNewPassword(!showNewPassword)
  }

  const handleShowConfirmPassword = (): void => {
    setShowConfirmPassword(!showConfirmPassword)
  }

  const onSubmit: SubmitHandler<ChangePasswordParam> = (data: ChangePasswordParam) => {
    setIsShowLoading(true)
    const newPasswordParam = {
      oldPassword: data.oldPassword,
      newPassword: data.newPassword,
    }
    createNewPassword(newPasswordParam)
      .then(() => showAlert('success', 'Senha alterada com sucesso!'))
      .catch(() => showAlert('error', 'Erro ao alterar a senha!'))
      .finally(() => {
        setIsShowLoading(false)
        navigate('/main/home')
      })
  }

  const newPassword = watch('newPassword')

  React.useEffect(() => {
    setValidationStatus!({
      length: newPassword?.length >= 12,
      number: /[0-9]/.test(newPassword),
      uppercase: /[A-Z]/.test(newPassword),
      lowercase: /[a-z]/.test(newPassword),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(newPassword),
    })
  }, [newPassword, setValidationStatus])

  React.useEffect(() => {
    setTitle('Editar Senha')
    setCustomHeaderContent(<div />)
    setItemsBreadCrumb([...defaultBreadCrumbItems, { label: 'Editar Senha', path: '/main/change-password/form' }])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={classes.formContainer}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="none">
        <Grid container spacing={2} className={classes.formContainer}>
          <Grid item xs={8}>
            <FormLabel component="label" sx={{ color: '#484946' }}>
              Senha atual
              <Controller
                name="oldPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputText
                    id="oldPassword"
                    data-cy="old-password-input"
                    label=""
                    type={showOldPassword ? 'text' : 'password'}
                    sx={{ marginTop: '5px' }}
                    InputProps={{
                      endAdornment: (
                        <ShowPassword showPassword={showOldPassword} handleShowPassword={handleShowOldPassword} />
                      ),
                    }}
                    errorText={formState.errors.oldPassword?.message}
                    {...field}
                  />
                )}
              />
            </FormLabel>
          </Grid>
          <Grid item xs={8}>
            <FormLabel component="label" sx={{ color: '#484946' }}>
              Nova senha
              <Controller
                name="newPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputText
                    id="newPassword"
                    data-cy="new-password-input"
                    label=""
                    type={showNewPassword ? 'text' : 'password'}
                    sx={{ marginTop: '5px' }}
                    InputProps={{
                      endAdornment: (
                        <ShowPassword showPassword={showNewPassword} handleShowPassword={handleShowNewPassword} />
                      ),
                    }}
                    errorText={formState.errors.newPassword?.message}
                    {...field}
                  />
                )}
              />
            </FormLabel>
          </Grid>
          <Grid item xs={8}>
            <FormLabel component="label" sx={{ color: '#484946' }}>
              Confirmar a nova senha
              <Controller
                name="confirmPassword"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <InputText
                    id="confirmPassword"
                    data-cy="confirm-password-input"
                    label=""
                    type={showConfirmPassword ? 'text' : 'password'}
                    sx={{ marginTop: '5px' }}
                    InputProps={{
                      endAdornment: (
                        <ShowPassword
                          showPassword={showConfirmPassword}
                          handleShowPassword={handleShowConfirmPassword}
                        />
                      ),
                    }}
                    errorText={formState.errors.confirmPassword?.message}
                    {...field}
                  />
                )}
              />
            </FormLabel>
          </Grid>
        </Grid>
        <div className={classes.buttonsContainer}>
          <Grid item xs={4}>
            <Button variant="outlined" type="button" className={classes.buttonStyle} sx={{ borderRadius: '100px' }}>
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={4}>
            <Button
              variant="contained"
              data-cy="confirm-update-password-button"
              color="primary"
              type="submit"
              className={classes.buttonStyle}
              sx={{ borderRadius: '100px' }}
            >
              Salvar
            </Button>
          </Grid>
        </div>
      </form>
    </div>
  )
}

export default NewPasswordForm

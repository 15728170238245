import React from 'react'
import { Card, Grid, IconButton } from '@mui/material'
import DataTable from '../../../../../DataTable/DataTable'
import { ClientTotal, FinancialTransactionInfo } from '../../../../../../models/Client/Client'
import Icons from '../../../../../../assets/icons/svgIcons'
import { useStyles } from '../../ClientFinancialStyle'
import { useStyles as useGlobalStyles } from '../../../../CompanyFormStyle'
import { ApplicationColors } from '../../../../../../utils/applicationColors'
import EditDeletePopover from '../../../../../PopOver/EditDeletePopover'
import DeleteConfirmation, { DeleteItemText } from '../../../../../DeleteConfirmation/DeleteConfirmation'

interface FinancialTableProps {
  title: string
  financialData: FinancialTransactionInfo[] | ClientTotal[]
  financialMap: { key: string; value: string }[]
  loading: boolean
  addTransaction?: () => void
  handleEdit?: (item: FinancialTransactionInfo) => void
  handleDelete?: (item: FinancialTransactionInfo) => void
  total: string
  totalTypePositive: boolean
}

const FinancialTable: React.FC<FinancialTableProps> = ({
  title,
  financialData,
  financialMap,
  loading,
  addTransaction,
  handleEdit,
  handleDelete,
  total,
  totalTypePositive,
}) => {
  const classes = useStyles()
  const globalClasses = useGlobalStyles()
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [anchor, setAnchor] = React.useState<any>(null)
  const [itemToChange, setItemToChange] = React.useState<FinancialTransactionInfo>()
  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = React.useState<boolean>(false)
  const [currentDeleteItem, setCurrentDeleteItem] = React.useState<FinancialTransactionInfo | null>(null)
  const [deleteItemTexts, setDeleteItemTexts] = React.useState<DeleteItemText>({ title: '', item: '', name: '' })

  const handleMoreOptions = (
    item: FinancialTransactionInfo,
    index?: number,
    anchorEl?: EventTarget & HTMLButtonElement
  ): void => {
    setIsOpen(true)
    setAnchor(anchorEl)
    setItemToChange(item)
  }

  const handleClose = (): void => {
    setIsOpen(false)
    setAnchor(null)
  }

  const openDeleteConfirmation = (item: FinancialTransactionInfo): void => {
    setIsShowDeleteConfirmation(true)
    setDeleteItemTexts({
      title: 'Excluir modelo',
      item: 'o modelo',
      name: item.description,
    })
    setCurrentDeleteItem(item)
  }

  const handleDeleteConfirmationClose = (): void => {
    setIsShowDeleteConfirmation(false)
  }

  const handleConfirmDelete = (): void => {
    if (currentDeleteItem) {
      handleDelete!(currentDeleteItem)
    }
    setCurrentDeleteItem(null)
    handleDeleteConfirmationClose()
    setIsOpen(false)
  }

  React.useEffect(() => {
    if (handleEdit) {
      handleClose()
    }
  }, [handleEdit])

  return (
    <>
      <DeleteConfirmation
        handleConfirmDelete={handleConfirmDelete}
        isShowDeleteConfirmation={isShowDeleteConfirmation}
        handleClose={handleDeleteConfirmationClose}
        deleteItemTexts={deleteItemTexts}
      />
      <Card className="form-card" variant="outlined">
        <Grid item xs={12} className={classes.financialTableHeader}>
          <h2 className={globalClasses.tabTitle}>{title}</h2>
          {addTransaction && (
            <IconButton onClick={addTransaction} size="medium" className={classes.newTransactionButton}>
              <Icons.Add fill="white" />
            </IconButton>
          )}
        </Grid>
        <Grid item className={classes.financialTable}>
          <DataTable
            compact
            data={financialData}
            dataMap={financialMap}
            loading={loading}
            config={{ showPagination: false }}
            actions={
              handleDelete || handleEdit
                ? [
                    {
                      label: 'Opções',
                      icon: <Icons.MoreVertical fill={ApplicationColors.primaryColor} />,
                      fn: handleMoreOptions,
                    },
                  ]
                : []
            }
          />
        </Grid>
        <Grid container className={classes.financialTotalValueContainer}>
          <Grid
            item
            className={`${classes.financialTotalValue} ${
              totalTypePositive ? classes.finacialPositiveValue : classes.finacialNegativeValue
            }`}
          >
            <h4 style={{ fontWeight: 'lighter', color: 'white' }}>R$ {total}</h4>
          </Grid>
        </Grid>
      </Card>
      <EditDeletePopover
        editFunction={handleEdit}
        deleteFunction={openDeleteConfirmation}
        open={isOpen}
        item={itemToChange}
        handleClose={handleClose}
        anchor={anchor}
      />
    </>
  )
}

export default FinancialTable

import React from 'react'
import { Card, FormLabel, Grid, Switch } from '@mui/material'
import { Controller, FieldError, UseFormReturn } from 'react-hook-form'
import InputText from '../../../../Form/Input/Input'
import { useStyles } from '../../../CompanyFormStyle'
import InputMaskCNPJ from '../../../../Form/Input/InputMaskCNPJ'
import { ClientFormType } from '../../../../../models/Company/Company'
import InputFile from '../../../../Form/Input/InputFile'

interface ClientPrimaryInfoProps {
  form: UseFormReturn<ClientFormType>
}

const ClientPrimaryInfo: React.FC<ClientPrimaryInfoProps> = ({ form }) => {
  const { control, formState, setValue, getValues } = form
  const companyFormclasses = useStyles()

  const handleCompanysFacadeFileChange = (file: File | undefined): void => {
    setValue('companysFacade', file)
  }

  return (
    <Card className="form-card" variant="outlined">
      <h2 className={companyFormclasses.tabTitle}>Dados primários</h2>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} key="corporateName">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Razão Social*
            <Controller
              name="corporateName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText
                  id="corporateName"
                  label=""
                  errorText={formState.errors?.corporateName?.message}
                  {...field}
                />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="cnpj">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            CNPJ*
            <Controller
              name="cnpj"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputMaskCNPJ id="cnpj" label="" errorText={formState.errors?.cnpj?.message} {...field} />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="companyName">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Nome*
            <Controller
              name="companyName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText id="companyName" label="" errorText={formState.errors?.companyName?.message} {...field} />
              )}
            />
          </FormLabel>
        </Grid>
        <Grid item xs={12} sm={6} key="greenMonitorLink">
          <FormLabel component="label" className={companyFormclasses.labelStyle}>
            Relatório Monitor Verde
            <Controller
              name="greenMonitorLink"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <InputText
                  id="greenMonitorLink"
                  label=""
                  errorText={formState.errors?.greenMonitorLink?.message}
                  {...field}
                />
              )}
            />
          </FormLabel>
        </Grid>
        {false && (
          <Grid item xs={12} sm={6} key="companyesFacade">
            <FormLabel
              component="label"
              sx={{ display: 'flex', flexDirection: 'column', marginTop: '6px' }}
              className={companyFormclasses.labelStyle}
            >
              Fachada da Empresa
              <Controller
                name="companysFacade"
                control={control}
                render={() => (
                  <InputFile
                    id="companysFacade"
                    file={getValues('companysFacade') || undefined}
                    fileLink={getValues('companysFacadeLink') || ''}
                    documentName="Fachada"
                    isPartnerDoc={false}
                    onChange={handleCompanysFacadeFileChange}
                  />
                )}
              />
            </FormLabel>
          </Grid>
        )}
      </Grid>
    </Card>
  )
}

export default ClientPrimaryInfo

import { Button, Card, Grid } from '@mui/material'
import React from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import LogoBeta from '../../../assets/images/logo-beta.png'
import Alerts from '../../../components/Alerts/Alerts'
import InputText from '../../../components/Form/Input/Input'
import Loading from '../../../components/Loading/Loading'
import { Credentials } from '../../../models/User/user'
import { authenticate } from '../../../services/Auth/LoginService'
import { saveToken, saveUser } from '../../../services/Auth/TokenService'
import useYupValidationResolver from '../../../utils/yup-validator-resolver'
import useStyles from './LoginStyles'
import { getThisUser } from '../../../services/User/UserService'
import handleAmplifiqueMeResearch from '../../../AmplifiqueMeResearch'

const validationSchema = yup.object({
  username: yup.string().required('campo obrigatório'), // .email("email inválido"),
  password: yup.string().required('campo obrigatório'),
})

const LoginForm: React.FC = () => {
  const styles = useStyles()
  const navigate = useNavigate()
  const [isShowAlert, setIsShowAlert] = React.useState<boolean>(false)
  const [isShowLoading, setIsShowLoading] = React.useState<boolean>(false)
  const resolver = useYupValidationResolver(validationSchema)
  const { control, formState, handleSubmit } = useForm<Credentials>({ resolver })

  const authHandler = (credentials: Credentials): void => {
    setIsShowLoading(true)
    authenticate(credentials)
      .then((res) => {
        saveToken(res.access_token)
        getThisUser()
          .then((user) => {
            saveUser(user)
            handleAmplifiqueMeResearch(user)
          })
          .finally(() => {
            navigate('/main/home')
          })
      })
      .catch(() => {
        setIsShowAlert(true)
      })
      .finally(() => {
        setIsShowLoading(false)
      })
  }

  const onSubmit: SubmitHandler<Credentials> = (credentials: Credentials) => {
    const credent = { ...credentials }
    credent.username = credent.username.trim()
    authHandler(credent)
  }

  return (
    <>
      <Alerts
        isShowAlert={isShowAlert}
        setIsShowAlert={setIsShowAlert}
        alertText="Usuário ou senha inválidos."
        alertType="error"
      />
      <Loading isShowLoading={isShowLoading} />
      <div className={styles.background}>
        <Card className={styles.card}>
          <img className={styles.logo} src={LogoBeta} alt="logo" />
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="none">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Controller
                  name="username"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputText id="username" label="Email" errorText={formState.errors?.username?.message} {...field} />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <InputText
                      id="password"
                      label="Senha"
                      type="password"
                      errorText={formState.errors?.password?.message}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginTop: '24px',
              }}
            >
              <Button variant="contained" color="primary" type="submit">
                Entrar
              </Button>
            </Grid>
          </form>
        </Card>
      </div>
    </>
  )
}

export default LoginForm

import React from 'react'
import { UseFormReturn } from 'react-hook-form'
import { ClientFormType } from '../../../../models/Company/Company'
import ClientFinancial from './components/ClientFinancial'

interface ClientFinancialTabProps {
  form: UseFormReturn<ClientFormType>
}

const ClientFinancialTab: React.FC<ClientFinancialTabProps> = ({ form }) => {
  return <ClientFinancial form={form} />
}

export default ClientFinancialTab

import React from 'react'
import { Chip, FormLabel, Grid, Typography } from '@mui/material'
import { ClientResponseI } from '../../../models/Client/Client'
import useStyles from '../Styles/ViewInfoModalStyle'
import { getChipColor, getChipLabel } from '../../../utils/getChipTagInfo'
import treatDateFormat from '../../../utils/treatDateFormat'

interface ContractDataProps {
  client: ClientResponseI
}

const ClientContractData: React.FC<ContractDataProps> = ({ client }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={2} className={classes.mainCard}>
      <Grid
        item
        xs={12}
        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
        key="contract-title"
      >
        <h2 className={classes.tabTitle}>Dados do contrato</h2>
        <Chip
          label={getChipLabel(client.contract.expirationStatus)}
          color={getChipColor(client.contract.expirationStatus)}
        />
      </Grid>
      <Grid item xs={6} key="contract-plan">
        <FormLabel className={classes.labelStyle} component="label">
          Plano Adquirido
        </FormLabel>
        <Typography variant="body1">{client.contract.purchasedPlan}</Typography>
      </Grid>
      <Grid item xs={6} key="contract-cashback">
        <FormLabel className={classes.labelStyle} component="label">
          Cashback
        </FormLabel>
        <Typography variant="body1">{(client.contract.cashbackPercentage * 100).toFixed(2)} %</Typography>
      </Grid>
      <Grid item xs={6} key="contract-paymentDate">
        <FormLabel className={classes.labelStyle} component="label">
          Prazo para pagamento
        </FormLabel>
        <Typography variant="body1">{client.contract.paymentTime} dia(s)</Typography>
      </Grid>
      <Grid item xs={6} key="contract-minimumValue">
        <FormLabel className={classes.labelStyle} component="label">
          Valor mínimo para Cashback
        </FormLabel>
        <Typography variant="body1">{client.contract.minimumValue}</Typography>
      </Grid>
      <Grid item xs={6} key="contract-expirationDate">
        <FormLabel className={classes.labelStyle} component="label">
          Data de Expiração
        </FormLabel>
        <Typography variant="body1">{treatDateFormat(client.contract.expirationDate as string)}</Typography>
      </Grid>
    </Grid>
  )
}

export default ClientContractData

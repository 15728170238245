import axios from 'axios'

import { AuthenticateReponse, CheckToken, Credentials } from '../../models/User/user'
import getEndpointUrl from '../../utils/url-resolver'

const baseUrlCompany = getEndpointUrl('company').replace('/api', '/oauth')

const authenticate = (credentials: Credentials): Promise<AuthenticateReponse> => {
  const headers = {
    Authorization: 'Basic cmVjaWNsYWNsdWI6cmVjaWNsYTkzMTc=',
    'Content-Type': 'application/x-www-form-urlencoded',
  }

  const urlencoded = new URLSearchParams()
  urlencoded.append('password', credentials.password)
  urlencoded.append('username', credentials.username)
  urlencoded.append('grant_type', 'password')

  return axios.post(`${baseUrlCompany}/token`, urlencoded, { headers }).then((response) => response.data)
}

const checkToken = (): Promise<CheckToken> => {
  const headers = {
    redirect: 'follow',
  }

  return axios.get(`${baseUrlCompany}/v1/check_token`, { headers })
}

export { authenticate, checkToken }

import React from 'react'
import { useParams } from 'react-router-dom'
import { UseFormReturn } from 'react-hook-form'
import { Button, ButtonGroup, Card } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { DatePicker, StaticDatePicker } from '@mui/x-date-pickers'
import { ca, ptBR as localePtBR } from 'date-fns/locale'
import { ClientTotal, FinancialTransactionInfo, FinancialTransactionRequest } from '../../../../../models/Client/Client'
import FinancialTable from './FinancialTable/FinancialTable'
import {
  createFinancialTransaction,
  deleteFinancialTransaction,
  getExecutedFinancialInfo,
  getExecutedFinancialInfoByMonth,
  getPlannedFinancialInfo,
  updateFinancialTransaction,
} from '../../../../../services/Client/FinancialService'
import { ClientFormType } from '../../../../../models/Company/Company'
import AppContext, { AppContextType } from '../../../../../AppContext'
import TransactionsForm from './TransactionsForm/TransactionsForm'
import { useStyles } from '../ClientFinancialStyle'
import treatDateFormat from '../../../../../utils/treatDateFormat'

interface ClientFinancialProps {
  form: UseFormReturn<ClientFormType>
}

const ClientFinancial: React.FC<ClientFinancialProps> = ({ form }) => {
  const [allTransactions, setAllTransactions] = React.useState<FinancialTransactionInfo[]>(
    [] as FinancialTransactionInfo[]
  )
  const [costs, setCosts] = React.useState<FinancialTransactionInfo[]>([] as FinancialTransactionInfo[])
  const [revenues, setRevenues] = React.useState<FinancialTransactionInfo[]>([] as FinancialTransactionInfo[])
  const [comissions, setComissions] = React.useState<FinancialTransactionInfo[]>([] as FinancialTransactionInfo[])
  const [totalCosts, setTotalCosts] = React.useState<ClientTotal[]>([] as ClientTotal[])
  const [executed, setExecuted] = React.useState<boolean>(false)
  const [origin, setOrigin] = React.useState<string>('' as string)
  const [transactionFormTitle, setTransactionFormTitle] = React.useState<string>('' as string)
  const [openTransactionForm, setOpenTransactionForm] = React.useState<boolean>(false)
  const [loading, setLoading] = React.useState<boolean>(false)
  const [transactionToEdit, setTransactionToEdit] = React.useState<FinancialTransactionInfo | undefined>(undefined)
  const [onEditIdentifier, setOnEditIdentifier] = React.useState<string>('' as string)
  const [executedDate, setExecutedDate] = React.useState<Date>(new Date())
  const { identifier } = useParams<{ identifier: string }>()
  const { showAlert, setIsShowLoading } = React.useContext(AppContext as React.Context<AppContextType>)
  const classes = useStyles()

  const originFieldsMap: { key: string; value: string }[] = [
    { key: 'type', value: 'Tipo' },
    { key: 'description', value: 'Descrição' },
    { key: 'quantity', value: 'Quantidade' },
    { key: 'unitMeasurement', value: 'Unidade de Medida' },
    { key: 'unitValue', value: 'Valor Unitário' },
    { key: 'totalValue', value: 'Valor Total' },
  ]

  const costFieldsMap = [...originFieldsMap]

  if (executed) {
    costFieldsMap.splice(2, 0, { key: 'executedDate', value: 'Data' })
  }

  const commissionFieldsMap = originFieldsMap.map((item) => {
    if (item.key === 'unitMeasurement') {
      return { key: 'unitMeasurement', value: 'Condição' }
    }
    return item
  })

  const totalFieldsMap = [
    { key: 'origin', value: 'Origem' },
    { key: 'description', value: 'Descrição' },
    { key: 'totalValue', value: 'Valor Total' },
  ]

  const translateType = (data: FinancialTransactionInfo[]): FinancialTransactionInfo[] => {
    return data.map((item) => {
      if (item.type === 'FIXED') {
        return { ...item, type: 'Fixo' }
      }
      return { ...item, type: 'Variável' }
    })
  }

  const setTotalItem = (
    data: FinancialTransactionInfo[],
    itemType: string,
    itemOrigin: string,
    description: string
  ): void => {
    const transactions = data.filter((item) => item.type === itemType)
    const totalValue = transactions.reduce((acc, item) => acc + Number(item.totalValue), 0)
    const totalValueItem = {
      origin: itemOrigin,
      description,
      totalValue: totalValue.toFixed(2) as unknown as number,
    }
    setTotalCosts((prevState) => [...prevState, totalValueItem])
  }

  const setData = (
    data: FinancialTransactionInfo[],
    originReference: string,
    setDataFunction: React.Dispatch<React.SetStateAction<FinancialTransactionInfo[]>>
  ): void => {
    const filteredData = data.filter((item) => item.origin === originReference)
    const fixedValueMessage = originReference === 'COST' ? 'Custos fixos' : 'Receitas fixas'
    const variableValueMessage = originReference === 'COST' ? 'Custos variáveis' : 'Receitas variáveis'
    const totalValueMessage = originReference === 'COST' ? 'Custos' : 'Receitas'
    if (filteredData) {
      const translatedData = translateType(filteredData)
      setDataFunction(translatedData)
      setTotalItem(translatedData, 'Fixo', totalValueMessage, fixedValueMessage)
      setTotalItem(translatedData, 'Variável', totalValueMessage, variableValueMessage)
    }
  }

  const setCommissionsData = (data: FinancialTransactionInfo[]): void => {
    const commissionsData = data.filter((item) => item.origin === 'COMMISSION')
    if (commissionsData) {
      const translatedCommissionsData = translateType(commissionsData)
      setComissions(translatedCommissionsData)
      setTotalCosts((prevState) => [
        ...prevState,
        {
          origin: 'Comissões',
          description: 'Comissões',
          totalValue: translatedCommissionsData.reduce((acc, item) => acc + Number(item.totalValue), 0).toFixed(2),
        },
      ])
    }
  }

  const setFinancialData = (financialInfo: FinancialTransactionInfo[]): void => {
    setAllTransactions(financialInfo)
    setData(financialInfo, 'COST', setCosts)
    setData(financialInfo, 'REVENUE', setRevenues)
    setCommissionsData(financialInfo)
  }

  const calculateMonthDifference = (date1: Date, date2: Date): number =>
    (date1.getFullYear() - date2.getFullYear()) * 12 + (date1.getMonth() - date2.getMonth())

  const getFinancialData = async (): Promise<void> => {
    try {
      setIsShowLoading(true)
      const currentDate = calculateMonthDifference(executedDate, new Date())
      if (identifier) {
        const financialInfo = executed
          ? await getExecutedFinancialInfoByMonth(identifier, currentDate)
          : await getPlannedFinancialInfo(identifier)
        if (financialInfo) {
          setFinancialData(financialInfo)
          setLoading(false)
        }
      }
    } catch (error) {
      showAlert('error', 'Erro ao buscar dados financeiros')
    } finally {
      setIsShowLoading(false)
    }
  }

  const addCostTransaction = (): void => {
    setTransactionFormTitle('Novo Custo')
    setOrigin('COST')
    setOpenTransactionForm(true)
  }

  const addRevenueTransaction = (): void => {
    setTransactionFormTitle('Nova Receita')
    setOrigin('REVENUE')
    setOpenTransactionForm(true)
  }

  const addComissionTransaction = (): void => {
    setTransactionFormTitle('Nova Comissão')
    setOrigin('COMMISSION')
    setOpenTransactionForm(true)
  }

  const handleEdit = (item: FinancialTransactionInfo): void => {
    const transaction = allTransactions.find((transactionItem) => transactionItem.identifier === item.identifier)
    setTransactionToEdit(transaction)
    setOnEditIdentifier(transaction!.identifier)
    switch (transaction!.origin) {
      case 'COST':
        setTransactionFormTitle('Editar Custo')
        setOrigin('COST')
        break
      case 'REVENUE':
        setTransactionFormTitle('Editar Receita')
        setOrigin('REVENUE')
        break
      case 'COMMISSION':
        setTransactionFormTitle('Editar Comissão')
        setOrigin('COMMISSION')
        break
      default:
        break
    }
    setOpenTransactionForm(true)
  }

  const handleDelete = async (item: FinancialTransactionInfo): Promise<void> => {
    try {
      await deleteFinancialTransaction(item.identifier)
      showAlert('success', 'Transação excluída com sucesso!')
      setTotalCosts([])
      getFinancialData()
    } catch {
      showAlert('error', 'Erro ao excluir transação')
    } finally {
      setIsShowLoading(false)
    }
  }

  const submitTransaction = async (data: FinancialTransactionRequest): Promise<void> => {
    const successMessage = onEditIdentifier ? 'Transação atualizada com sucesso!' : 'Transação criada com sucesso!'
    const errorMessage = onEditIdentifier ? 'Erro ao atualizar transação' : 'Erro ao criar transação'
    try {
      const request = onEditIdentifier
        ? await updateFinancialTransaction(data, onEditIdentifier)
        : await createFinancialTransaction(data)
      if (request) {
        showAlert('success', successMessage)
        setOpenTransactionForm(false)
        setTotalCosts([])
        getFinancialData()
      }
    } catch {
      showAlert('error', errorMessage)
    } finally {
      setIsShowLoading(false)
    }
  }

  const onSubmit = async (data: FinancialTransactionRequest): Promise<void> => {
    setIsShowLoading(true)
    const dataRequest = {
      ...data,
      customerIdentifier: identifier!,
      origin,
      executed,
      executedDate: executed ? data.executedDate : undefined,
    }
    submitTransaction(dataRequest)
  }

  React.useEffect(() => {
    setTotalCosts([])
    getFinancialData()
  }, [executed])

  const setCurrencyToValue = (data: FinancialTransactionInfo[]): FinancialTransactionInfo[] => {
    return data.map((item) => ({
      ...item,
      executedDate: item.executedDate && treatDateFormat(item.executedDate as string),
      totalValue: `R$ ${Number(item.totalValue).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
      unitValue: `R$ ${Number(item.unitValue).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}`,
    }))
  }

  const getTotalValueByOrigin = (data: FinancialTransactionInfo[]): string => {
    return data
      .reduce((acc, item) => acc + Number(item.totalValue), 0)
      .toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
  }

  const getTotalValue = (): number => {
    return Number(
      totalCosts
        .reduce((acc, item) => {
          if (item.origin === 'Receitas') {
            return acc + Number(item.totalValue)
          }
          return acc - Number(item.totalValue)
        }, 0)
        .toFixed(2)
    )
  }

  const setExecutedFinancialDataByDate = async (chosenDate: Date | null): Promise<void> => {
    setIsShowLoading(true)
    try {
      if (chosenDate) {
        setTotalCosts([])
        setExecutedDate(chosenDate)
        const currentDate = new Date()
        const monthDifference = calculateMonthDifference(chosenDate, currentDate)
        const executedInfo = await getExecutedFinancialInfoByMonth(identifier as string, monthDifference)
        setFinancialData(executedInfo)
      } else {
        throw new Error()
      }
    } catch (error) {
      showAlert('error', 'Erro ao buscar dados financeiros')
    } finally {
      setIsShowLoading(false)
    }
  }

  const onClearExecutedDateFilter = (): void => {
    setExecutedDate(new Date())
    setExecutedFinancialDataByDate(new Date())
  }

  return (
    <Card className="form-card" variant="outlined">
      <TransactionsForm
        title={transactionFormTitle}
        onSubmit={onSubmit}
        open={openTransactionForm}
        onClose={() => {
          setOpenTransactionForm(false)
          setTransactionToEdit(undefined)
          setOnEditIdentifier('')
        }}
        transactionToEdit={transactionToEdit}
        executed={executed}
      />
      <ButtonGroup disableElevation aria-label="Disabled elevation buttons" className={classes.buttonGroup}>
        <Button
          variant={!executed ? 'contained' : 'outlined'}
          className={classes.buttonGroupButton}
          onClick={() => setExecuted(false)}
        >
          Planejado
        </Button>
        <Button
          variant={executed ? 'contained' : 'outlined'}
          className={classes.buttonGroupButton}
          onClick={() => setExecuted(true)}
        >
          Executado
        </Button>
      </ButtonGroup>
      {executed && (
        <LocalizationProvider adapterLocale={localePtBR} dateAdapter={AdapterDateFns}>
          <DatePicker
            label=""
            views={['month', 'year']}
            maxDate={new Date(new Date().getFullYear(), new Date().getMonth(), 31)}
            minDate={new Date('2023-02-01')}
            slotProps={{
              openPickerIcon: { fontSize: 'large', color: 'white' },
              openPickerButton: { color: 'primary' },
              field: { clearable: true, onClear: () => onClearExecutedDateFilter() },
              textField: {
                variant: 'outlined',
                focused: true,
              },
              layout: {
                sx: {
                  // position: 'relative',
                  width: '398px',
                  '& .MuiPickersMonth-monthButton.Mui-selected': {
                    color: 'white',
                    backgroundColor: '#33B652',
                  },
                  '& .MuiPickersYear-yearButton.Mui-selected': {
                    color: 'white',
                    backgroundColor: '#33B652',
                  },
                },
              },
            }}
            value={executedDate}
            onAccept={(date) => setExecutedFinancialDataByDate(date)}
            sx={{
              marginLeft: '20px',
              width: '398px',
              backgroundColor: 'white',
              border: 'none',
            }}
          />
        </LocalizationProvider>
      )}
      <FinancialTable
        title="Custos"
        financialData={setCurrencyToValue(costs)}
        financialMap={costFieldsMap}
        loading={loading}
        addTransaction={addCostTransaction}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        total={getTotalValueByOrigin(costs)}
        totalTypePositive={false}
      />
      <FinancialTable
        title="Receitas"
        financialData={setCurrencyToValue(revenues)}
        financialMap={originFieldsMap}
        loading={loading}
        addTransaction={addRevenueTransaction}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        total={getTotalValueByOrigin(revenues)}
        totalTypePositive
      />
      <FinancialTable
        title="Comissões"
        financialData={setCurrencyToValue(comissions)}
        financialMap={commissionFieldsMap}
        loading={loading}
        addTransaction={addComissionTransaction}
        handleEdit={handleEdit}
        handleDelete={handleDelete}
        total={getTotalValueByOrigin(comissions)}
        totalTypePositive={false}
      />
      <FinancialTable
        title="Total"
        financialData={totalCosts.map((item) => ({
          ...item,
          totalValue: `R$ ${Number(item.totalValue).toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}`,
        }))}
        financialMap={totalFieldsMap}
        loading={loading}
        total={getTotalValue().toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
        totalTypePositive={getTotalValue() >= 0 && true}
      />
    </Card>
  )
}

export default ClientFinancial

import * as yup from 'yup'

const validationSchema = yup.object({
  companyName: yup.string().required('Nome é obrigatório'),
  cnpj: yup.string().required('CNPJ é obrigatório'),
  corporateName: yup.string().required('Razão social é obrigatório'),
  cep: yup.string().required('CEP é obrigatório'),
  state: yup.string().required('Estado é obrigatório'),
  city: yup.string().required('Cidade é obrigatório'),
  district: yup.string().required('Bairro é obrigatório'),
  street: yup.string().required('Logradouro é obrigatório'),
  number: yup
    .number()
    .typeError('Favor inserir um número válido')
    .min(1, 'Valor invalido')
    .required('Número é obrigatório'),
  branch: yup
    .number()
    .typeError('Favor inserir um número válido')
    .min(1, 'Valor invalido')
    .required('Filial é obrigatório'),
  cashbackPercentage: yup.number().required('Porcentagem de cashback é obrigatório'),
  paymentTime: yup.number().required('Tempo de pagamento é obrigatório'),
  purchasedPlan: yup.string().required('Plano de compras é obrigatório'),
  expirationDate: yup.string().required('Data de expiração é obrigatório'),
})

export default validationSchema

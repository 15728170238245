import React from 'react'
import { useNavigate } from 'react-router-dom'
import { IconButton, Card, Grid } from '@mui/material'
import DataTable from '../../../components/DataTable/DataTable'
import InputSelect2, { Select2Options } from '../../../components/Form/Select/Select2'
import AppContext, { AppContextType } from '../../../AppContext'
import { defaultBreadCrumbItems } from '../../../components/BreadCrumb/BreadCrumb'
import { deleteClient, getAllClients } from '../../../services/Client/ClientService'
import Input from '../../../components/Form/Input/Input'
import maskCNPJ from '../../../utils/masks/maskCNPJ'
import { iconAccessCheck } from '../../../utils/AccessPermissionsCheck'
import Icons from '../../../assets/icons/svgIcons'
import { ApplicationColors } from '../../../utils/applicationColors'
import DeleteConfirmation, { DeleteItemText } from '../../../components/DeleteConfirmation/DeleteConfirmation'
import EditDeletePopover from '../../../components/PopOver/EditDeletePopover'
import type { CompanyMinimalInfo } from '../../../models/Company/Company'
import { ItemType } from '../../../utils/sort'
import ViewInfoModal from '../../../components/ViewInfoModal/ViewInfoModal'

const ClientList: React.FC = () => {
  const { showAlert, setIsShowLoading, setTitle, setCustomHeaderContent, setItemsBreadCrumb } = React.useContext(
    AppContext as React.Context<AppContextType>
  )
  const [allClients, setAllClients] = React.useState<CompanyMinimalInfo[]>([])
  const [clientsList, setClientsList] = React.useState<CompanyMinimalInfo[]>([])
  const [clientsOptions, setClientsOptions] = React.useState<Select2Options[]>([])
  const [selectedCnpj, setSelectedCnpj] = React.useState<string | undefined>(undefined)
  const [isOpen, setIsOpen] = React.useState<boolean>(false)
  const [itemToChange, setItemToChange] = React.useState<CompanyMinimalInfo>()
  const [anchor, setAnchor] = React.useState<any>(null)
  const [currentDeleteItem, setCurrentDeleteItem] = React.useState<CompanyMinimalInfo | null>(null)
  const [isShowDeleteConfirmation, setIsShowDeleteConfirmation] = React.useState<boolean>(false)
  const [deleteItemTexts, setDeleteItemTexts] = React.useState<DeleteItemText>({ title: '', item: '', name: '' })
  const [clientInfoModalOpen, setClientInfoModalOpen] = React.useState<boolean>(false)
  const [modalSelectedClient, setModalSelectedClient] = React.useState<CompanyMinimalInfo>({} as CompanyMinimalInfo)
  const [loading, setLoading] = React.useState<boolean>(false)
  const clientsMap = [
    { key: 'name', value: 'Nome' },
    { key: 'cnpj', value: 'CNPJ' },
  ]
  const navigate = useNavigate()

  const setCompanyOptionsList = (allClientsList: CompanyMinimalInfo[]): Select2Options[] => {
    return allClientsList.map((client) => {
      const option = { label: client.name, value: client.identifier as unknown as string }
      return option
    })
  }

  const setAllClientsList = async (): Promise<void> => {
    setIsShowLoading(true)
    setLoading(true)
    getAllClients()
      .then((clients) => {
        setAllClients(clients)
        setClientsList(clients)
        const allClientsOptions = [{ label: 'Todos', value: 'Todos' }, ...setCompanyOptionsList(clients)]
        setClientsOptions(allClientsOptions)
      })
      .catch(() => {
        showAlert('error', 'Erro ao buscar clientes')
      })
      .finally(() => {
        setIsShowLoading(false)
        setLoading(false)
      })
  }

  const onChangeByName = (event: React.SyntheticEvent<Element, Event>, value: Select2Options | null): void => {
    setLoading(true)
    if (value?.value === 'Todos') {
      setClientsList(allClients)
      setLoading(false)
    } else {
      const newClientsList = allClients.filter((client) =>
        client.name.toLowerCase().includes(String(value?.label).toLowerCase())
      )
      setClientsList(newClientsList)
      setLoading(false)
    }
  }

  const onChangeByCNPJ = (event: React.SyntheticEvent<Element, Event>, value: string): void => {
    setLoading(true)
    const unmaskedCnpj = maskCNPJ(value)
    const newClientsList = allClients.filter((client) => client.cnpj.includes(unmaskedCnpj))
    setClientsList(newClientsList)
    setLoading(false)
  }

  const openDeleteConfirmation = (item: CompanyMinimalInfo): void => {
    setIsShowDeleteConfirmation(true)
    setDeleteItemTexts({
      title: 'Excluir modelo',
      item: 'o modelo',
      name: item.name,
    })
    setCurrentDeleteItem(item)
  }

  const handleEdit = (item: CompanyMinimalInfo): void => {
    navigate(`/main/clients/form/${item.identifier}`)
  }

  const handleDelete = (item: CompanyMinimalInfo): void => {
    setIsShowLoading(true)
    deleteClient(item.identifier)
      .then(() => {
        const newClientsList = allClients.filter((v) => v.identifier !== item.identifier)
        setAllClients(newClientsList)
        setClientsList(newClientsList)
        showAlert('success', 'Modelo excluído com sucesso.')
        setIsShowLoading(false)
      })
      .catch(() => {
        showAlert('error', 'Erro ao excluir modelo.')
        setIsShowLoading(false)
      })
  }

  const handleDeleteConfirmationClose = (): void => {
    setIsShowDeleteConfirmation(false)
  }

  const handleConfirmDelete = (): void => {
    if (currentDeleteItem) {
      handleDelete(currentDeleteItem)
    }
    setCurrentDeleteItem(null)
    handleDeleteConfirmationClose()
    setIsOpen(false)
  }

  const handleClose = (): void => {
    setIsOpen(false)
  }

  const handleMoreOptions = (
    item: CompanyMinimalInfo,
    index?: number,
    anchorEl?: EventTarget & HTMLButtonElement
  ): void => {
    setIsOpen(true)
    setAnchor(anchorEl)
    setItemToChange(item)
  }

  const handleClickNew = React.useCallback(() => {
    navigate('/main/clients/form')
  }, [navigate])

  const customHeaderContent = React.useMemo(
    () => (
      <IconButton aria-label="Nova empresa" onClick={handleClickNew} data-cy="go-to-clientform-button" size="large">
        <Icons.Add fill="white" />
      </IconButton>
    ),
    [handleClickNew]
  )

  const handleOpenClientInfoModal = (client: ItemType<any>): void => {
    const clientInfo = {
      name: client.name as string,
      cnpj: client.cnpj as string,
      identifier: client.identifier as string,
    }
    setModalSelectedClient(clientInfo)
    setClientInfoModalOpen(true)
  }

  const handleCloseClientInfoModal = (): void => {
    setClientInfoModalOpen(false)
  }

  React.useEffect(() => {
    setAllClientsList()
    setTitle('Clientes')
    setCustomHeaderContent(customHeaderContent)
    setItemsBreadCrumb([...defaultBreadCrumbItems, { label: 'Listar clientes', path: '/main/clients/list' }])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <ViewInfoModal
        open={clientInfoModalOpen}
        handleModalClose={handleCloseClientInfoModal}
        client={modalSelectedClient}
      />
      <DeleteConfirmation
        handleConfirmDelete={handleConfirmDelete}
        isShowDeleteConfirmation={isShowDeleteConfirmation}
        handleClose={handleDeleteConfirmationClose}
        deleteItemTexts={deleteItemTexts}
      />
      <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Grid item xs={6}>
          <Card className="card-filters" style={{ borderRadius: '4px', padding: '16px' }} variant="outlined">
            <InputSelect2
              id="clientOption"
              label="Cliente"
              options={clientsOptions}
              onChange={onChangeByName}
              clearable
              sx={{ borderRadius: '4px' }}
              value={undefined}
            />
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card className="card-filters" style={{ borderRadius: '4px', padding: '16px' }} variant="outlined">
            <Input
              id="cnpj"
              label="CNPJ"
              onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) =>
                onChangeByCNPJ(event, event.target.value)
              }
              sx={{ borderRadius: '4px' }}
              value={selectedCnpj}
            />
          </Card>
        </Grid>
      </Grid>
      <div className="filter--open">
        <DataTable
          data={clientsList}
          dataMap={clientsMap}
          loading={loading}
          actions={
            iconAccessCheck('company-edit') || iconAccessCheck('company-delete')
              ? [
                  {
                    label: 'Opções',
                    icon: <Icons.MoreVertical fill={ApplicationColors.primaryColor} />,
                    fn: handleMoreOptions,
                  },
                ]
              : []
          }
          handleOpenInfoModal={handleOpenClientInfoModal}
        />
      </div>
      <EditDeletePopover
        editFunction={iconAccessCheck('company-edit') ? handleEdit : undefined}
        deleteFunction={iconAccessCheck('company-delete') ? openDeleteConfirmation : undefined}
        open={isOpen}
        item={itemToChange}
        handleClose={handleClose}
        anchor={anchor}
      />
    </>
  )
}

export default ClientList

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton, Typography } from '@mui/material'
import React from 'react'
import AppContext, { AppContextType } from '../../../../AppContext'
import { retryGathering } from '../../../../services/Gathering/GatheringService'
import Icons from '../../../../assets/icons/svgIcons'

export type HelpDialogProps = {
  open: boolean
  submittedGathering: number | undefined
  handleClose: () => void
}

const HelpDialog: React.FC<HelpDialogProps> = ({ open, submittedGathering, handleClose }: HelpDialogProps) => {
  const { showAlert, setIsShowLoading } = React.useContext(AppContext as React.Context<AppContextType>)

  const handleRetry = (): void => {
    setIsShowLoading(true)
    retryGathering(submittedGathering as number)
      .then(() => {
        showAlert('success', 'PDF gerado com sucesso.')
        setIsShowLoading(false)
      })
      .catch(() => {
        showAlert('error', 'Erro ao gerar PDF.')
        setIsShowLoading(false)
      })
  }

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
      <DialogTitle id="form-dialog-title">
        Atenção
        <IconButton
          aria-label="close"
          onClick={handleClose}
          style={{
            position: 'absolute',
            right: 8,
            top: 8,
            // color: (theme) => theme.palette.grey[500],
          }}
          size="large"
        >
          <Icons.Close />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>Ocorreu um erro na sua requisição.</Typography>
        <Typography gutterBottom>Isto pode ter sido causado por algum procedimento fora do esperado.</Typography>
        <Typography gutterBottom>
          Você pode tentar novamente, ou obter suporte entrando em contato via whatsapp ou email.
          <br />
          <br />
          Whatsapp: 31 993220143 <br />
          Email: qualidade@recicla.club
        </Typography>
      </DialogContent>
      <DialogActions>
        <a
          href="mailto:qualidade@recicla.club?subject=Erro%20ao%20gerar%20MTR&body=Olá!%20Tive%20um%20problema%20ao%20gerar%20um%20MTR.%20Você%20poderia%20me%20ajudar?"
          target="_blank"
          style={{ textDecoration: 'none' }}
          rel="noreferrer"
        >
          <Button variant="contained" color="secondary" startIcon={<Icon>email</Icon>}>
            Email
          </Button>
        </a>
        <a
          href="https://wa.me/5531993220143?text=Olá!%20Tive%20um%20problema%20ao%20gerar%20um%20MTR.%20Você%20poderia%20me%20ajudar?"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
        >
          <Button variant="contained" color="primary" startIcon={<Icon>whatsapp</Icon>}>
            Whatsapp
          </Button>
        </a>
        <Button
          style={{ marginLeft: '8px' }}
          variant="outlined"
          color="primary"
          onClick={handleRetry}
          startIcon={<Icon>replay</Icon>}
        >
          Tentar Novamente
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default HelpDialog

import { Theme } from '@mui/material/styles'

import createStyles from '@mui/styles/createStyles'
import makeStyles from '@mui/styles/makeStyles'

export const documentStyles = makeStyles((theme: Theme) =>
  createStyles({
    tableStyle: {
      border: '1px solid #E9E9E8',
    },
    tabTitle: {
      fontWeight: 'lighter',
    },
    nameTableCellStyle: {
      width: '20%',
      [theme.breakpoints.down('lg')]: {
        width: '15%',
      },
    },
    nameTypographyStyle: {
      textAlign: 'center',
    },
    dischargTableCellStyle: {
      width: '25%',
      [theme.breakpoints.down('lg')]: {
        width: '30%',
      },
    },
  })
)

import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from 'react-hook-form'

import ClientForm from '../ClientForm'

import { ClientFormType, CompanyFormType, CompanyInfo } from '../../../../models/Company/Company'
import AppContext, { AppContextType } from '../../../../AppContext'
import { defaultBreadCrumbItems } from '../../../../components/BreadCrumb/BreadCrumb'

import { saveClient, createClientContact, saveClienteFacade } from '../../../../services/Client/ClientService'
import { ClientRequestI } from '../../../../models/Client/Client'

import useYupValidationResolver from '../../../../utils/yup-validator-resolver'
import validationSchema from '../utils/companyValidationSchema'
import setRequestData, { setClientContactData } from '../utils/setRequestData'
import { ClientContract } from '../../../../models/Client/ClientContract'
import { createClientContract } from '../../../../services/Client/ClientContractService'

const NewClientForm: React.FC = () => {
  const resolver = useYupValidationResolver(validationSchema)
  const form = useForm<ClientFormType>({ resolver })
  const { setIsShowLoading, showAlert, setItemsBreadCrumb, setTitle } = React.useContext(
    AppContext as React.Context<AppContextType>
  )
  const navigate = useNavigate()

  const requestCreateCompany = async (companyRequestInfo: ClientRequestI): Promise<string> => {
    setIsShowLoading(true)
    try {
      const response = await saveClient(companyRequestInfo)
      showAlert('success', 'Cliente registrado com sucesso')
      return response.identifier!
    } catch {
      setIsShowLoading(false)
      throw new Error('Erro ao criar novo cliente')
    }
  }

  const requestSaveFacade = async (identifier: string, file: File): Promise<void> => {
    setIsShowLoading(true)
    try {
      await saveClienteFacade(identifier, file)
      showAlert('success', 'Fachada salva com sucesso.')
    } catch {
      setIsShowLoading(false)
      throw new Error('Erro ao criar novo cliente')
    }
  }

  const requestSaveContract = async (identifier: string, data: ClientFormType): Promise<void> => {
    const contractInfo: ClientContract = {
      cashbackPercentage: data.cashbackPercentage / 100,
      paymentTime: data.paymentTime,
      purchasedPlan: data.purchasedPlan,
      status: data.status ? 1 : 0,
      minimumValue: data.minimumValue,
      expirationDate: data.expirationDate,
    }
    try {
      await createClientContract(contractInfo, identifier)
    } catch {
      throw new Error('Erro ao salvar informações do contrato')
    } finally {
      setIsShowLoading(false)
    }
  }

  const requestSaveContacts = async (identifier: string, data: ClientFormType): Promise<string> => {
    const clientContacts = setClientContactData(data)
    await Promise.all(
      clientContacts.map(async (contact) => {
        try {
          if (contact.name !== '' && contact.name !== undefined) {
            await createClientContact(identifier, contact)
          }
        } catch {
          throw new Error('Erro ao salvar contatos')
        }
      })
    )
    return Promise.resolve(identifier)
  }

  const onSubmit = (data: ClientFormType): void => {
    const companyRequestInfo = setRequestData(data)
    requestCreateCompany(companyRequestInfo)
      .then((identifier) => {
        const file = form.getValues('companysFacade')
        if (file) {
          requestSaveFacade(identifier, file)
        }
        return Promise.resolve(identifier)
      })
      .then((identifier) => {
        requestSaveContract(identifier, data)
        return Promise.resolve(identifier)
      })
      .then((identifier) => requestSaveContacts(identifier, data))
      .then((identifier) => navigate(`/main/clients/form/${identifier}`))
      .catch((error: Error) => showAlert('error', error.message))
  }

  React.useEffect(() => {
    setTitle('Novo cliente')
    form.setValue('branch', 0)
    setItemsBreadCrumb([
      ...defaultBreadCrumbItems,
      { label: 'Listar Clientes', path: '/main/clients/list' },
      { label: 'Novo Cliente', path: '/main/clients/form' },
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <ClientForm form={form} onSubmit={onSubmit} />
}

export default NewClientForm

import React from 'react'
import { useForm, type SubmitHandler } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import * as yup from 'yup'

import AppContext, { AppContextType } from '../../../../AppContext'
import { CompanyMinimalInfo } from '../../../../models/Company/Company'
import MtrModel, { type MtrModelResponse } from '../../../../models/MtrModel/MtrModel'
import { Select2Options } from '../../../../components/Form/Select/Select2'
import { editMtrModel, getMtrModelByIdentifier, saveMtrModel } from '../../../../services/MtrModel/MtrModelService'
import useYupValidationResolver from '../../../../utils/yup-validator-resolver'
import { defaultBreadCrumbItems } from '../../../../components/BreadCrumb/BreadCrumb'
import MtrModelMainForm from '../MtrModelMainForm'
import { getPartnersByCustomerIdentifier } from '../../../../services/Client/ClientPartnerService'
import type { PartnerMinimalResponse } from '../../../../models/Partner/Partner'
import setRequestData from '../utils/setRequestData'

const validationSchema = yup.object({
  mtrModelIdentifier: yup.string(),
  name: yup.string().required('campo obrigatório'),
  generatorIdentifier: yup.string().required('campo obrigatório'),
  receiverIdentifier: yup.string().required('campo obrigatório'),
  transporterIdentifier: yup.string().required('campo obrigatório'),
  vehicleIdentifier: yup.string(),
  driverIdentifier: yup.string(),
  mtrObservation: yup.string(),
  wasteModel: yup.array().required('campo obrigatório').min(1),
})

const MtrModelForm: React.FC = () => {
  const resolver = useYupValidationResolver(validationSchema)
  const useFormObject = useForm<MtrModel>({
    resolver,
  })
  const { setValue, reset } = useFormObject
  const { id } = useParams<{ id: string }>()
  const [contractsMap, setContractsMap] = React.useState<Select2Options[]>([])
  const [vehiclesMap, setVehiclesMap] = React.useState<Select2Options[]>([])
  const [companiesMap, setCompaniesMap] = React.useState<Select2Options[]>([])
  const { setTitle, setCustomHeaderContent, setIsShowLoading, showAlert, setItemsBreadCrumb } = React.useContext(
    AppContext as React.Context<AppContextType>
  )

  const handleContractData = (partners: CompanyMinimalInfo[]): void => {
    const contracts = partners.map((p) => {
      return { label: p.name, value: p.identifier }
    })
    setContractsMap(contracts)
  }
  const setContractsByGeneratorIdentifier = (customerIdentifier: string): void => {
    getPartnersByCustomerIdentifier(customerIdentifier).then((contractData) => {
      handleContractData(contractData)
    })
  }

  const setGeneratorInfo = (identifier: string | undefined): void => {
    if (identifier) setValue('generatorIdentifier', identifier)
  }
  const setTransporterInfo = (identifier: string | undefined): void => {
    if (identifier) setValue('transporterIdentifier', identifier)
  }
  const setReceiverInfo = (identifier: string | undefined): void => {
    if (identifier) setValue('receiverIdentifier', identifier)
  }

  const setVehiclesInfo = (identifier: string | undefined): void => {
    if (identifier) setValue('vehicleIdentifier', identifier)
  }
  const setDriverInfo = (identifier: string | undefined): void => {
    if (identifier) setValue('driverIdentifier', identifier)
  }

  const setBaseInfo = (model: MtrModelResponse): void => {
    setValue('mtrModelIdentifier', model.identifier)
    setValue('name', model.name)
    setValue('mtrObservation', model.mtrObservation)
    setValue('wasteModel', model.wasteModel ?? [])
  }

  const loadManifestInfo = (model: MtrModelResponse): void => {
    setBaseInfo(model)
    setGeneratorInfo(model.generatorIdentifier)
    setContractsByGeneratorIdentifier(model.generatorIdentifier)
    setTransporterInfo(model.transporterIdentifier)
    setReceiverInfo(model.receiverIdentifier)
    setVehiclesInfo(model.vehicleIdentifier)
    setDriverInfo(model.driverIdentifier)
  }

  const handleInit = React.useCallback(async (): Promise<void> => {
    if (id) {
      const mtrModel = await getMtrModelByIdentifier(id)
      if (mtrModel) {
        loadManifestInfo(mtrModel)
      }
    }
  }, [id, reset, setIsShowLoading, showAlert])

  React.useEffect(() => {
    setIsShowLoading(true)

    handleInit().finally(() => {
      setIsShowLoading(false)
    })
    setTitle('Editar Modelo de MTR')
    setCustomHeaderContent(<div />)
    setItemsBreadCrumb([
      ...defaultBreadCrumbItems,
      { label: 'Listar modelos de MTR', path: '/main/mtrmodel/list' },
      { label: 'Editar modelo MTR', path: '/main/mtrmodel/form' },
    ])
  }, [handleInit, setCustomHeaderContent, setTitle])

  const onSubmit: SubmitHandler<MtrModel> = (data: MtrModel) => {
    setIsShowLoading(true)
    const requestData = setRequestData(data)
    editMtrModel(data.mtrModelIdentifier!, requestData)
      .then(() => {
        setIsShowLoading(false)
        showAlert('success', 'Modelo editado com sucesso.')
      })
      .catch(() => {
        showAlert('error', 'Erro ao editar modelo. Tente novamente mais tarde.')
      })
      .finally(() => {
        setIsShowLoading(false)
      })
  }
  return (
    <MtrModelMainForm
      contractsMap={contractsMap}
      setContractsMap={setContractsMap}
      vehiclesMap={vehiclesMap}
      setVehiclesMap={setVehiclesMap}
      companiesMap={companiesMap}
      setCompaniesMap={setCompaniesMap}
      handleInit={handleInit}
      onSubmit={onSubmit}
      useFormObject={useFormObject}
    />
  )
}

export default MtrModelForm
